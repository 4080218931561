<template>
    <div>
        <v-overlay v-if="isloading"></v-overlay>
        <v-row v-if="info.text">
            <v-col md="12" lg="8" cols="12" class="ps-0">
                <v-card flat :tile="$vuetify.breakpoint.smAndDown" class="pa-5 mb-4">
                    <div class="mb-7">
                        <pro-editable-name v-model="qrlabel" 
                            :codeid="$route.params.codeid" 
                            @change="e=>qrlabel=e">
                        </pro-editable-name>
                    </div>
                    <pseudo-form ref="qrform">
                        <component v-bind:is="'demo-form-' + data.shortname" ref="miniform" 
                            @change="autoSubmit">
                        </component>
                    </pseudo-form>
                </v-card>
                <!-- QR styles -->
                <v-card flat :tile="$vuetify.breakpoint.smAndDown" class="pa-5 mb-4">
                    <qrstyles ref="qrstyles" :expanded="0"
                        @change="autoSubmit">
                    </qrstyles>
                </v-card>
            </v-col>
            <!-- QR preview -->
            <v-col md="12" lg="4" cols="12" class="pe-0">
                <div class="demo-image-container smfm-sticky-md-16">
                    <qr-preview :iconname="data.shortname" ref="qrpreview" class="mb-4"></qr-preview>
                    <pro-qr-download-button :qrlabel="qrlabel" ref="qrdownload">
                    </pro-qr-download-button>
                </div>
            </v-col>
        </v-row>
        <v-alert v-if="invalidcode" type="error" v-html="$t('pro.codenotfound')">
            <!-- TODO different messages for different errors? -->
        </v-alert>
    </div>
</template>

<script>
    import { mapActions, mapGetters } from "vuex";
    import qrMixin from "@/mixins/qrMixin";
    import qrdownload from "@/mixins/qrdownload";
    import userMixin from "@/mixins/userMixin";
    import PseudoForm from "@/components/forms/PseudoForm";
    import Qrstyles from "@/components/forms/Qrstyles";
    import QrPreview from "@/components/demo/QrPreview";
    import ProEditableName from "@/components/pro/edit/ProEditableName";
    import ProQrDownloadButton from "@/components/pro/download/ProQrDownloadButton";

    export default {
        name: "ProEditStatic",
        components: {ProQrDownloadButton, ProEditableName, QrPreview, Qrstyles, PseudoForm },
        mixins: [ qrMixin, userMixin, qrdownload ],
        data() {
            return {
                data: {},
                isloading: false,
                qrlabel: '',
                //lastvalue: {},
                info: {},
                invalidcode: false,
                formvalue: null,
            }
        },
        computed: {
            ...mapGetters({currentqr: 'getCurrentQr'}),
            codeid() { return this.$route.params.codeid },
        },
        created() {
            this.isloading = true
            this.resetCurrentQr()
            let data
            this.authGet({url: 'pro/codes/' + this.codeid})
                .then(res => {
                    data = res.data
                    this.info = data
                    this.qrlabel = data.name
                    let cards = this.staticcards[this.userLang]
                    const filtered = Object.keys(cards).filter(a => cards[a].shortname === data.type)
                    this.data = filtered.length ? cards[filtered[0]] : {}
                    this.$nextTick(() => {
                        this.$refs.qrstyles.setConfig(data.config)
                        this.$refs.miniform.value = data.value
                    })
                    //this.lastvalue = {data: data.text}
                    return this.authGet({url: this.info.qrurl})
                })
                .then(resp => {
                    this.setCurrentQr({
                        image: resp.data,
                        codeid: this.codeid,
                        qrurl: data.qrurl,
                        qrlabel: data.name,
                    })
                })
                .catch(() => this.invalidcode = true)
                .finally(() => this.isloading = false)
        },
        methods: {
            ...mapActions(['resetCurrentQr', 'setCurrentQr']),
            displayQr() {
                if (this.lastvalue && this.$refs.qrstyles) {
                    let params = {
                        text: this.lastvalue.data,
                        config: JSON.parse(JSON.stringify(this.$refs.qrstyles.config)),
                        value: JSON.parse(JSON.stringify(this.$refs.miniform.value)),
                    }
                    //console.log(params)
                    const promise = this.authPost({url: 'pro/codes/'+this.codeid, params})
                    this.refreshQrPro(promise, this.lastvalue.data)
                } else {
                    this.resetCurrentQr()
                }
            },
            autoSubmit() {
                this.$nextTick(() => {
                    if (this.$refs.qrform.form.validate()) {
                        this.lastvalue = this.$refs.miniform.qrdata
                        this.displayQr()
                        this.$refs.qrform.submit()
                    }
                })
            },
        }
    }
</script>
