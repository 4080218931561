<template>
    <div>
        <v-text-field outlined name="url" :label="$t('app.forms.enterurl')" v-model="url"
            :rules="[requiredRule, urlRule]" ref="inp" dir="ltr"
            :prepend-icon="codeid ? iconUrl : ''"
            @change="$emit('change')">
        </v-text-field>
    </div>
</template>

<script>
    import { mdiWeb } from "@mdi/js";
    import validation from "@/mixins/validation";

    export default {
        name: "DynamicFormUrl",
        mixins: [ validation ],

        props: {
            codeid: null,
        },

        data() {
            return {
                url: ""
            };
        },

        computed: {
            value: {
                get() {
                    return {url: this.url}
                },
                set(newValue) {
                    this.url = newValue.url
                }
            },
            label() {
                return this.url
            },
            iconUrl() { return mdiWeb }
        },

        methods: {
            focusMain() {
                this.$refs.inp.focus();
                this.$refs.inp.$el.querySelector('input').select();
            },
        },
    }
</script>

<style scoped>

</style>
