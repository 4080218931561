<template>
    <div class="temp-files">
        <loading v-if="!tempfilesloaded" :size="2" class="my-10"></loading>
        <div v-else class="tempfilesdrop" :class="$refs.upload && $refs.upload.dropActive ? ['drop-target'] : []">
            <div class="smfm-pro-subtitle mb-5" v-html="$t('pro.api.descriptiontempfiles')">
            </div>
            <div class="mt-3 mb-5">
                <v-btn class="smfm-primary-btn me-3" @click="onAddFile">{{ $t('pro.api.addfiles') }}</v-btn>
                <v-btn class="smfm-light-green-btn" @click="onAddFolder">{{ $t('pro.api.addfolder') }}</v-btn>
                <file-upload
                    class="btn btn-primary dropdown-toggle"
                    :custom-action="doUpload"
                    :extensions="extensions"
                    :multiple="multiple"
                    :directory="directory"
                    :create-directory="createDirectory"
                    :size="size || 0"
                    :thread="thread < 1 ? 1 : (thread > 5 ? 5 : thread)"
                    drop=".tempfilesdrop"
                    :drop-directory="dropDirectory"
                    :add-index="addIndex"
                    v-model="files"
                    @input-filter="inputFilter"
                    @input-file="inputFile"
                    ref="upload">
                </file-upload>
            </div>
            <div class="tempfileslist">
                <div v-for="file in files" :key="'file_'+file.name">
                    {{file.name}}
                    <copy-to-clipboard :value="file.name"></copy-to-clipboard>
                </div>
            </div>
            <!--                        <file-upload :drop="true" :drop-directory="true"></file-upload>-->
        </div>
    </div>
</template>

<script>
    import { mapActions, mapGetters } from "vuex";
    import { mdiRefresh } from "@mdi/js";
    import userMixin from "@/mixins/userMixin";
    import CopyToClipboard from "@/components/forms/CopyToClipboard";
    import FileUpload from 'vue-upload-component';
    import EventBus from '@/plugins/EventBus.js';
    import Loading from "@/components/common/Loading";

    // https://github.com/lian-yue/vue-upload-component/blob/master/docs/views/examples/Full.vue
    export default {
        name: "ProTempFiles",
        mixins: [ userMixin ],
        components: { CopyToClipboard, FileUpload, Loading },
        data() {
            return {
                files: [],
                // TODO more
                extensions: 'gif,jpg,jpeg,png,webp,pdf,mp3,docx,xlsx,txt',
                //accept: 'image/png,image/gif,image/jpeg,image/webp',
                // extensions: 'gif,jpg,jpeg,png,webp',
                // extensions: ['gif', 'jpg', 'jpeg','png', 'webp'],
                // extensions: /\.(gif|jpe?g|png|webp)$/i,
                //minSize: 1024,
                size: 0, //1024 * 1024 * 10, // TODO
                multiple: true,
                directory: false,
                dropDirectory: true,
                createDirectory: false,
                addIndex: false,
                thread: 1,
                name: 'file',
                autoCompress: 1024 * 1024,
                uploadAuto: true,
                isOption: false,
                addData: {
                    show: false,
                    name: '',
                    type: '',
                    content: '',
                },
                editFile: {
                    show: false,
                    name: '',
                },
                iconRefresh: mdiRefresh,
            }
        },
        computed: {
            ...mapGetters(['tempfiles', 'tempfilesloaded']),
        },
        created() {
            this.reloadTempFiles()
        },
        methods: {
            ...mapActions(['loadTempFiles']),
            reloadTempFiles(force = false) {
                return this.loadTempFiles(force)
                    .then(() => {
                        this.files = []
                        for (let file of this.tempfiles) {
                            this.files.push({name: file.name, size: file.size, type: ''})
                        }
                    })
            },
            inputFilter(newFile, oldFile, prevent) {
                if (newFile && !oldFile) {
                    // Before adding a file
                    // Filter system files or hide files
                    if (/(\/|^)(Thumbs\.db|desktop\.ini|\..+)$/.test(newFile.name)) {
                        return prevent()
                    }
                    // Filter php html js file
                    if (/\.(php5?|html?|jsx?)$/i.test(newFile.name)) {
                        return prevent()
                    }
                    // Automatic compression
                    // if (newFile.file && newFile.type.substr(0, 6) === 'image/' && this.autoCompress > 0 && this.autoCompress < newFile.size) {
                    //     newFile.error = 'compressing'
                    //     const imageCompressor = new ImageCompressor(null, {
                    //         convertSize: Infinity,
                    //         maxWidth: 512,
                    //         maxHeight: 512,
                    //     })
                    //     imageCompressor.compress(newFile.file)
                    //         .then((file) => {
                    //             this.$refs.upload.update(newFile, { error: '', file, size: file.size, type: file.type })
                    //         })
                    //         .catch((err) => {
                    //             this.$refs.upload.update(newFile, { error: err.message || 'compress' })
                    //         })
                    // }
                }
                if (newFile && (!oldFile || newFile.file !== oldFile.file)) {
                    // Create a blob field
                    newFile.blob = ''
                    let URL = window.URL || window.webkitURL
                    if (URL && URL.createObjectURL) {
                        newFile.blob = URL.createObjectURL(newFile.file)
                    }
                    // Thumbnails
                    newFile.thumb = ''
                    if (newFile.blob && newFile.type.substr(0, 6) === 'image/') {
                        newFile.thumb = newFile.blob
                    }
                }
            },
            inputFile(newFile, oldFile) {
                if (newFile && !oldFile) {
                    let orphaned = null
                    for (let file of this.files) {
                        if (file.name === newFile.name && file !== newFile) {
                            orphaned = file
                        }
                    }
                    if (orphaned) {
                        this.files.splice(this.files.indexOf(orphaned), 1)
                    }
                }
                // add, update, remove File Event
                if (newFile && oldFile) {
                    // update
                    if (newFile.active && !oldFile.active) {
                        // beforeSend
                        // min size
                        if (newFile.size >= 0 && this.minSize > 0 && newFile.size < this.minSize) {
                            this.$refs.upload.update(newFile, { error: 'size' })
                        }
                    }
                    if (newFile.progress !== oldFile.progress) {
                        // progress
                    }
                    if (newFile.error && !oldFile.error) {
                        // error
                    }
                    if (newFile.success && !oldFile.success) {
                        // success
                    }
                }
                if (!newFile && oldFile) {
                    // remove
                    if (oldFile.success && oldFile.response.id) {
                        // $.ajax({
                        //   type: 'DELETE',
                        //   url: '/upload/delete?id=' + oldFile.response.id,
                        // })
                    }
                }
                // Automatically activate upload
                if (Boolean(newFile) !== Boolean(oldFile) || oldFile.error !== newFile.error) {
                    if (this.uploadAuto && !this.$refs.upload.active) {
                        this.$refs.upload.active = true
                    }
                }
            },
            onAddFile() {
                let input = document.createElement('input')
                input.style = "background: rgba(255, 255, 255, 0);overflow: hidden;position: fixed;width: 1px;height: 1px;z-index: -1;opacity: 0;"
                input.type = 'file'
                input.multiple = true
                document.querySelector("body").appendChild(input)
                input.click()
                input.onchange = () => {
                    input.files.forEach((file) => this.uploadFile(file))
                }
            },
            uploadFile(file) {
                let apidata = {}
                const type = file.type
                return this.authPost({url: 'pro/tempfiles', params: {filename: file.name, type}})
                    .then(resp => {
                        apidata = resp.data
                        return this.s3Put({url: apidata.uploadurl, body: file, headers: apidata.uploadheaders})
                    })
                    .finally(() => this.reloadTempFiles(true))
            },
            onAddFolder() {
                if (!this.$refs.upload.features.directory) {
                    this.alert(this.$t('pro.api.nofolderupload'))
                    return
                }
                let input = document.createElement('input')
                input.style = "background: rgba(255, 255, 255, 0);overflow: hidden;position: fixed;width: 1px;height: 1px;z-index: -1;opacity: 0;"
                input.type = 'file'
                input.setAttribute('allowdirs', true)
                input.setAttribute('directory', true)
                input.setAttribute('webkitdirectory', true)
                input.multiple = true
                document.querySelector("body").appendChild(input)
                input.click()
                input.onchange = () => {
                    // TODO ProTempFiles.vue:207 Uncaught TypeError: e.$refs.upload.addInputFile(...).then is not a function
                    this.$refs.upload.addInputFile(input).then(function() {
                        document.querySelector("body").removeChild(input)
                    })
                }
            },
            doUpload(file) {
                // this.loading = true
                let apidata = {}
                const type = file.type
                return this.authPost({url: 'pro/tempfiles', params: {filename: file.name, type}})
                    .then(resp => {
                        apidata = resp.data
                        return this.s3Put({url: apidata.uploadurl, body: file.file, headers: apidata.uploadheaders})
                    })
            }
        },
        mounted() {
            EventBus.$on('refreshTempFiles', () => this.reloadTempFiles(true));
        },
        beforeDestroy() {
            EventBus.$off('refreshTempFiles');
        }
    }
</script>

<style scoped lang="scss">
.temp-files {
    .drop-target {
        background: lightgray;
    }
    .tempfileslist {
        max-height: 400px;
        overflow-y: scroll;
    }
}
</style>