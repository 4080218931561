var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.isloading)?_c('v-overlay'):_vm._e(),_c('div',{staticClass:"d-flex flex-wrap justify-start mt-2"},[_c('simple-dialog',{attrs:{"header":_vm.$t('pro.confirm'),"no-title":true},on:{"save":_vm.deleteAllData},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var open = ref.open;
return [_c('v-btn',{staticClass:"delete-btn pa-0",class:{'light': _vm.light},attrs:{"text":"","ripple":false},on:{"click":function($event){$event.preventDefault();return open($event)}}},[_vm._v(" "+_vm._s(_vm.$t('pro.profile.deletemydata'))+" ")])]}},{key:"buttons",fn:function(ref){
var save = ref.save;
return [_c('v-btn',{staticClass:"smfm-lg-btn",attrs:{"color":"error"},on:{"click":save}},[_vm._v(_vm._s(_vm.$t('pro.profile.deletemydata')))])]}}])},[_vm._v(" "+_vm._s(_vm.$t('pro.profile.confirmdeletedata'))+" ")]),(_vm.canDeleteAccount)?_c('simple-dialog',{attrs:{"header":_vm.$t('pro.confirm'),"no-title":true},on:{"save":_vm.deleteAccount},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var open = ref.open;
return [_c('v-btn',{staticClass:"delete-btn pa-0 ms-7",class:{'light': _vm.light},attrs:{"text":"","ripple":false},on:{"click":function($event){$event.preventDefault();return open($event)}}},[_vm._v(" "+_vm._s(_vm.$t('pro.profile.deletemyaccount'))+" ")])]}},{key:"buttons",fn:function(ref){
var save = ref.save;
return [_c('v-btn',{staticClass:"smfm-lg-btn",attrs:{"color":"error"},on:{"click":save}},[_vm._v(_vm._s(_vm.$t('pro.profile.deletemyaccount')))])]}}],null,false,3959273149)},[_c('p',{domProps:{"innerHTML":_vm._s(_vm.$t('pro.profile.confirmdeleteaccount1'))}}),_c('p',{domProps:{"innerHTML":_vm._s(_vm.$t('pro.profile.confirmdeleteaccount2'))}}),(_vm.userPlan.active && !_vm.userPlan.isTrial)?_c('p',{domProps:{"innerHTML":_vm._s(_vm.$t('pro.profile.confirmdeleteaccount3'))}}):_vm._e(),_c('p',{domProps:{"innerHTML":_vm._s(_vm.$t('pro.profile.confirmdeleteaccount4', {days: _vm.selfDeleteAccount}))}})]):_vm._e()],1),_c('simple-dialog',{ref:"accountdeleted",attrs:{"persistent":"","buttontext":_vm.$t('pro.profile.returntodemo'),"show-cancel":false,"show-close":false,"title":" "},on:{"save":_vm.goToApp}},[_vm._v(" "+_vm._s(_vm.$t('pro.profile.accountclosed'))+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }