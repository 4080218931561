<template>
    <v-card flat class="smfm-pro-settings-card smfm-w-100" 
        :class="[$vuetify.breakpoint.smAndDown ? 'pa-5' : 'pa-7', formOnly ? 'no-paddings' : '']">
        <div class="smfm-page-subheading mb-6" v-if="!formOnly">{{ $t('pro.profile.preferences') }}</div>
        <div v-if="userinfo">
            <v-row>
                <v-col cols="12" :md="!formOnly ? 12 : 6" 
                    :class="[$vuetify.breakpoint.smAndDown ? 'pa-0' : 'py-0 ps-0', !formOnly ? 'no-paddings' : '']">
                    <v-select outlined v-model="userinfo.locale" :items="languagesSelect"
                        :label="$t('pro.profile.preflanguage')">
                    </v-select>
                </v-col>
                <v-col cols="12" :md="!formOnly ? 12 : 6" 
                    :class="[$vuetify.breakpoint.smAndDown ? 'pa-0' : 'py-0 pe-0', !formOnly ? 'no-paddings' : '']">
                    <v-select outlined v-model="userinfo['custom:dateformat']" :items="dateformats"
                        :label="$t('pro.profile.prefdateformat')">
                    </v-select>
                </v-col>
                <v-col cols="12" :md="!formOnly ? 12 : 6"
                    :class="[$vuetify.breakpoint.smAndDown ? 'pa-0' : 'py-0 ps-0', !formOnly ? 'no-paddings' : '']">
                    <v-select outlined v-model="userinfo['custom:timeformat']" :items="timeformats"
                        :label="$t('pro.profile.preftimeformat')">
                    </v-select>
                </v-col>
                <v-col cols="12" :md="!formOnly ? 12 : 6"
                    :class="[$vuetify.breakpoint.smAndDown ? 'pa-0' : 'py-0 pe-0', !formOnly ? 'no-paddings' : '']">
                    <v-select outlined v-model="userinfo.zoneinfo" :items="timezones"
                        :label="$t('pro.profile.prefzoneinfo')">
                    </v-select>
                </v-col>                
            </v-row>
            <v-btn v-if="!formOnly" large class="smfm-primary-btn smfm-md-btn"
                :block="$vuetify.breakpoint.xsOnly"
                @click="changePreferences">
                {{ $t('pro.save') }}
            </v-btn>
        </div>
    </v-card>
</template>

<script>
    import momentTZ from 'moment-timezone'
    import userMixin from "@/mixins/userMixin";
    import {loadLanguageAsync} from "../../../i18n";

    export default {
        name: "ProPreferences",
        mixins: [ userMixin ],
        props: {
            formOnly: { default: false }
        },
        data() {
            return {
                userinfo: null,
            }
        },
        computed: {
            timezones() { return momentTZ.tz.names() },
            languagesSelect() {
                return Object.keys(this.languagesMenu).map(key => ({value: key, text: this.languagesMenu[key]}))
            },
            dateformats() {
                return [{value: 'DD/MM/YYYY', text: 'DD/MM/YYYY (31/01/2021)'},
                        {value: 'MM/DD/YYYY', text: 'MM/DD/YYYY (01/31/2021)'}]
            },
            timeformats() {
                return [{value: 'HH:mm', text: '24h (13:00)'},
                        {value: 'hh:mm A', text: '12h (01:00 PM)'}]
            }
        },
        created() {
            let isUSA = Intl.DateTimeFormat().resolvedOptions().locale === 'en-US'
            this.userinfo = {
                locale: this.userLang,
                'custom:dateformat': this.currentUser['custom:dateformat'] ?
                    this.currentUser['custom:dateformat'] : (isUSA ? 'MM/DD/YYYY' : 'DD/MM/YYYY'),
                'custom:timeformat': this.currentUser['custom:timeformat'] ?
                    this.currentUser['custom:timeformat'] : (isUSA ? 'hh:mm A' : 'HH:mm'),
                zoneinfo: this.currentUser.zoneinfo ? this.currentUser.zoneinfo : Intl.DateTimeFormat().resolvedOptions().timeZone
            }
        },
        methods: {
            changePreferences() {
                let toupdate = []
                for (let key of ['locale', 'zoneinfo', 'custom:dateformat', 'custom:timeformat'] ) {
                    if (!(key in this.currentUser) || !`${this.currentUser[key]}`.length || this.currentUser[key] !== this.userinfo[key]) {
                        toupdate.push({'Name': key, 'Value': this.userinfo[key]})
                    }
                }
                if (toupdate.length) {
                    return this.updateUser(toupdate)
                        .then(() => loadLanguageAsync(this.userLang))
                }
            }
        }
    }
</script>

<style scoped lang="scss">
.no-paddings {
    padding: 0 !important;
}
</style>