<template>
    <v-app class="smfm-app-wrapper gray-bg pro">
        <v-btn fab fixed right
            elevation="5"
            color="primary" 
            v-bind="$attrs"
            class="d-block d-md-none mt-2 mobile-menu"
            @click="drawer = !drawer">
            <v-icon color="white">
                {{ !drawer ? iconMenu : iconClose }}
            </v-icon>
        </v-btn>
        <!-- Navigation drawer -->
        <v-navigation-drawer v-model="drawer" app
            v-resize="onResize"
            v-if="isLoggedIn"
            :permanent="$vuetify.breakpoint.smAndDown ? false : true"
            :width="$vuetify.breakpoint.smAndDown && !drawer ? 0 : 286"
            :mini-variant="mini"
            mini-variant-width="72"
            class="smfm-pro-sidebar">
            <v-list-item>
                <v-list-item-content>
                    <v-list-item-title class="d-flex justify-space-between align-center smfm-logo" dir="ltr">
                        <v-badge :left="isRTL"
                            color="success"
                            content="PRO"
                            :class="[mini ? 'd-none' : 'd-flex']">
                            <router-link exact :to="{ name: 'dashboard' }">
                                <img :src="$vuetify.breakpoint.smAndDown ? '/images/logo-mobile.svg' : '/images/logo.svg'"
                                    :width="$vuetify.breakpoint.smAndDown ? 194 : 140"
                                    :height="$vuetify.breakpoint.smAndDown ? 44 : 50"
                                    alt="ScanMeFindMe">
                            </router-link>
                        </v-badge>
                        <v-btn icon color="white" :aria-label="$t('pro.togglenavbar')"
                            @click.stop="mini = !mini">
                            <v-icon>{{ mini ? iconRight : iconLeft }}</v-icon>
                        </v-btn>
                    </v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <router-link exact :to="{ name: 'dashboard' }"
                class="justify-center align-center pb-4"
                :class="[!mini ? 'd-none' : 'd-flex']">
                <img :src="'/images/logo-small.svg'" width="54" height="50" alt="ScanMeFindMe">
            </router-link>
            <v-divider></v-divider>
            <div class="px-3 py-5">
                <v-btn v-if="!mini"
                    x-large block
                    class="smfm-primary-btn px-4"
                    :to="{ name: 'create' }">
                    <div class="d-flex justify-center plus-wrap me-4">
                        <v-icon>{{ iconPlus }}</v-icon>
                    </div>
                    {{ $t('pro.createnewcode') }}
                </v-btn>
                <v-btn v-else fab dark small color="primary" class="ml-1"
                    :aria-label="$t('pro.createnewcode')"
                    :to="{ name: 'create' }">
                    <v-icon>{{ iconPlus }}</v-icon>
                </v-btn>
            </div>
            <v-divider class="mb-3"></v-divider>
            <v-list>
                <template v-for="item in navbaritemstop">
                    <v-list-item :key="item.name" link 
                        :exact="(item.name !== 'dynamic' && item.name !== 'static') ? true : false"
                        :to="{ name: item.name }" class="mx-3"
                        :disabled="userPlan.ended && item.name !== 'dashboard'"
                        @click="changeDrawerMode">
                        <v-tooltip right :disabled="!mini">
                            <template v-slot:activator="{ on }">
                                <v-list-item-icon v-on="on" class="my-3">
                                    <v-icon>{{ item.icon }}</v-icon>
                                </v-list-item-icon>
                            </template>
                            <span>{{ item.text }}</span>
                        </v-tooltip>
                        <v-list-item-content>
                            <v-list-item-title>
                                {{ item.text }}
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </template>
            </v-list>
            <v-divider></v-divider>
            <v-list>
                <template v-for="item in navbaritemsbottom">
                    <v-list-item :key="item.name" link :exact="item.name !== 'settings' ? true : false"
                        :to="{ name: item.name }" class="mx-3"
                        :disabled="userPlan.ended && item.name !== 'support'"
                        @click="changeDrawerMode">
                        <v-tooltip right :disabled="!mini">
                            <template v-slot:activator="{ on }">
                                <v-list-item-icon v-on="on" class="my-3">
                                    <v-icon color="#909295">{{ item.icon }}</v-icon>
                                </v-list-item-icon>
                            </template>
                            <span>{{ item.text }}</span>
                        </v-tooltip>
                        <v-list-item-content>
                            <v-list-item-title>
                                {{ item.text }}
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </template>
            </v-list>
            <!-- Sidebar bottom block -->
            <template v-slot:append>  
                <v-menu offset-y top left min-width="262">
                    <template v-slot:activator="{ on, attrs }">
                        <div v-bind="attrs" v-on="on"
                            class="d-flex justify-space-between align-center pa-4 user-menu">
                            <div class="d-flex align-center">
                                <v-avatar :size="40" class="me-3">
                                    <img v-if="currentUser.picture" :src="currentUser.picture">
                                    <div v-else class="d-flex justify-center align-center smfm-user-initials">
                                        {{ userInitials }}
                                    </div>
                                </v-avatar>
                                <div class="flex-column" :class="[mini ? 'd-none' : 'd-flex']">
                                    <div class="text-body-2">{{ currentUser.name }}</div>
                                    <div class="text-caption secondary--text">{{ currentUser.email }}</div>
                                </div>
                            </div>
                            <v-btn fab dark small color="#2B2E34" :aria-label="$t('pro.togglemenu')">
                                <v-icon color="white"> {{ iconArrows }} </v-icon>
                            </v-btn>
                        </div>
                    </template>
                    <v-card>
                        <v-list dense>
                            <template v-for="item in usermenu">
                                <v-list-item :key="item.name" link exact
                                    :to="{ name: item.name }"
                                    :disabled="userPlan.ended && item.name !== 'logout'"
                                    @click="changeDrawerMode">
                                    <v-list-item-action>
                                        <v-icon color="#393E46">{{ item.icon }}</v-icon>
                                    </v-list-item-action>
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            {{ item.text }}
                                        </v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </template>
                        </v-list>
                    </v-card>
                </v-menu>
            </template>
        </v-navigation-drawer>
        <v-main>
            <v-container v-cloak v-if="isLoggedIn" :class="{'px-0': $vuetify.breakpoint.smAndDown}">
                <keep-alive include="ProCreate">
                    <router-view :key="$route.fullPath"></router-view>
                </keep-alive>
            </v-container>
            <pro-login></pro-login>
        </v-main>
    </v-app>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex';
    import { 
        mdiImageMultipleOutline, mdiMenu, mdiClose, mdiExitToApp, mdiPlus,
        mdiAccountOutline, mdiArrowLeft, mdiArrowRight, mdiUnfoldMoreHorizontal 
    } from '@mdi/js';
    import EventBus from '@/plugins/EventBus.js';
    import ProLogin from "../components/pro/ProLogin";
    import userMixin from "@/mixins/userMixin";
    import customIcons from "@/mixins/customIcons";
    import { loadLanguageAsync } from "../i18n";

    export default {
        name: "Pro",
        components: { ProLogin },
        mixins: [ userMixin, customIcons ],
        data: () => ({
            dialog: false,
            drawer: false,
            mini: false,
            appname: null,
            windowWidth: 0,
            mediumScreen: false
        }),
        created() {
            this.appname = process.env.VUE_APP_NAME
            this.onLogin()
        },
        computed: {
            ...mapGetters('dashboard_pro', ['hasDynamicCodes']),
            iconMenu() { return mdiMenu },
            iconClose() { return mdiClose },
            iconPlus() { return mdiPlus },
            iconLeft() { return mdiArrowLeft },
            iconRight() { return mdiArrowRight },
            iconArrows() { return mdiUnfoldMoreHorizontal },
            qrcodeDynamic() {
                //return "M 3 11 L 5 11 L 5 13 L 3 13 Z M 11 5 L 13 5 L 13 9 L 11 9 Z M 9 11 L 13 11 L 13 15 L 11 15 L 11 13 L 9 13 Z M 15 11 L 17 11 L 17 11.919 C 16.276 12.145 15.602 12.486 15 12.92 Z M 19 11 L 21 11 L 21 11.864 C 20.393 11.693 19.753 11.601 19.091 11.601 C 19.061 11.601 19.03 11.601 19 11.602 Z M 11 21 L 11 17 L 12.275 17 C 12.155 17.514 12.091 18.05 12.091 18.601 C 12.091 19.444 12.24 20.252 12.513 21 Z M 15 3 L 21 3 L 21 9 L 15 9 Z M 17 5 L 17 7 L 19 7 L 19 5 Z M 3 3 L 9 3 L 9 9 L 3 9 Z M 5 5 L 5 7 L 7 7 L 7 5 Z M 3 15 L 9 15 L 9 21 L 3 21 Z M 5 17 L 5 19 L 7 19 L 7 17 Z M 23.581 20.02 C 23.681 20.02 23.681 20.12 23.581 20.22 L 22.581 21.92 C 22.481 22.02 22.381 22.02 22.281 22.02 L 21.081 21.62 C 20.781 21.82 20.581 21.92 20.281 22.12 L 20.081 23.42 C 20.081 23.52 19.981 23.62 19.881 23.62 L 17.881 23.62 C 17.781 23.62 17.681 23.52 17.581 23.42 L 17.381 22.12 C 17.081 22.02 16.781 21.82 16.581 21.62 L 15.381 22.12 C 15.281 22.12 15.181 22.12 15.081 22.02 L 14.081 20.32 C 13.981 20.22 14.081 20.12 14.181 20.02 L 15.281 19.22 L 15.281 18.22 L 14.181 17.42 C 14.081 17.32 14.081 17.22 14.081 17.12 L 15.081 15.42 C 15.181 15.32 15.281 15.32 15.381 15.32 L 16.581 15.82 C 16.881 15.62 17.081 15.52 17.381 15.32 L 17.581 14.02 C 17.581 13.92 17.681 13.82 17.881 13.82 L 19.881 13.82 C 19.981 13.82 20.081 13.92 20.081 14.02 L 20.281 15.32 C 20.581 15.42 20.881 15.62 21.181 15.82 L 22.381 15.32 C 22.481 15.32 22.681 15.32 22.681 15.42 L 23.681 17.12 C 23.781 17.22 23.681 17.32 23.581 17.42 L 22.481 18.22 L 22.481 19.22 Z M 20.281 18.62 C 20.281 17.82 19.581 17.12 18.781 17.12 C 17.981 17.12 17.281 17.82 17.281 18.62 C 17.281 19.42 17.981 20.12 18.781 20.12 C 19.581 20.12 20.281 19.42 20.281 18.62 Z"
                //return "M 3 11 L 5 11 L 5 13 L 3 13 Z M 3 15 L 9 15 L 9 21 L 3 21 Z M 5 17 L 5 19 L 7 19 L 7 17 Z M 5 5 L 5 7 L 7 7 L 7 5 Z M 3 3 L 9 3 L 9 9 L 3 9 Z M 17 5 L 17 7 L 19 7 L 19 5 Z M 15 3 L 21 3 L 21 9 L 15 9 Z M 11 21 L 11 17 L 12.275 17 C 12.155 17.514 12.091 18.05 12.091 18.601 C 12.091 19.444 12.24 20.252 12.513 21 Z M 19 11 L 21 11 L 21 11.864 C 20.393 11.693 19.753 11.601 19.091 11.601 C 19.061 11.601 19.03 11.601 19 11.602 Z M 15 11 L 17 11 L 17 11.919 C 16.276 12.145 15.602 12.486 15 12.92 Z M 9 11 L 13 11 L 13 15 L 11 15 L 11 13 L 9 13 Z M 11 5 L 13 5 L 13 9 L 11 9 Z M 22.404 18.331 C 22.404 19.151 22.154 19.911 21.734 20.541 L 20.644 19.451 C 20.814 19.111 20.904 18.731 20.904 18.331 C 20.904 16.951 19.784 15.831 18.404 15.831 L 18.404 17.331 L 16.154 15.081 L 18.404 12.831 L 18.404 14.331 C 20.614 14.331 22.404 16.121 22.404 18.331 Z M 18.404 19.331 L 20.654 21.581 L 18.404 23.831 L 18.404 22.331 C 16.194 22.331 14.404 20.541 14.404 18.331 C 14.404 17.511 14.654 16.751 15.074 16.121 L 16.164 17.211 C 15.994 17.551 15.904 17.931 15.904 18.331 C 15.904 19.711 17.024 20.831 18.404 20.831 Z"
                return "M 3 11 L 5 11 L 5 13 L 3 13 L 3 11 Z M 3 15 L 9 15 L 9 21 L 3 21 Z M 5 17 L 5 19 L 7 19 L 7 17 Z M 7 5 L 7 7 L 5 7 L 5 5 Z M 3 9 L 9 9 L 9 3 L 3 3 Z M 19 5 L 19 7 L 17 7 L 17 5 Z M 15 9 L 21 9 L 21 3 L 15 3 Z M 11 21 L 11 17 L 12.275 17 C 12.155 17.514 12.091 18.05 12.091 18.601 C 12.091 19.444 12.24 20.252 12.513 21 L 11 21 Z M 9 11 L 13 11 L 13 15 L 11 15 L 11 13 L 9 13 L 9 11 Z M 11 5 L 13 5 L 13 9 L 11 9 L 11 5 Z M 19 18.5 L 21.25 20.75 L 19 23 L 19 21.5 C 16.79 21.5 15 19.71 15 17.5 C 15 16.68 15.25 15.92 15.67 15.29 L 16.76 16.38 C 16.59 16.72 16.5 17.1 16.5 17.5 C 16.5 18.88 17.62 20 19 20 L 19 18.5 Z M 23 17.5 C 23 18.32 22.75 19.08 22.33 19.71 L 21.24 18.62 C 21.41 18.28 21.5 17.9 21.5 17.5 C 21.5 16.12 20.38 15 19 15 L 19 16.5 L 16.75 14.25 L 19 12 L 19 13.5 C 21.21 13.5 23 15.29 23 17.5"
            },
            navbaritemstop() {
                return [
                    { text: this.$t('pro.dashboard.dashboard'), name: 'dashboard', icon: this.iconDashboard },
                    { text: this.$t('pro.dynamiccodes'), name: 'dynamic', icon: this.iconDynamicCodes },
                    { text: this.$t('pro.staticcodes'), name: 'static', icon: this.iconQrstyle },
                    { text: this.$t('pro.library.library'), name: 'library', icon: mdiImageMultipleOutline },
                    { text: this.$t('app.api.title'), name: 'api', icon: this.iconApi },
                ]
            },
            navbaritemsbottom() {
                return [
                    { text: this.$t('pro.profile.settings'), name: 'settings', icon: this.iconSettings },
                    { text: this.$t('pro.profile.support'), name: 'support', icon: this.iconSupport },
                ]
            },
            usermenu() {
                return [
                    { text: this.$t('pro.profile.myaccount'), name: 'account', icon: mdiAccountOutline },
                    { text: this.$t('pro.profile.logout'), name: 'logout', icon: mdiExitToApp }
                ]
            },
        },
        methods: {
            ...mapActions(['loadUserLibrary', 'loadUserInfo']),
            onLogin() {
                // Executed when login process is finished and information about the user is retrieved.
                if (!this.isLoggedIn) { return }
                this.loadUserLibrary()
                this.loadUserInfo()
                loadLanguageAsync(this.userLang)
            },
            onResize () {
                this.windowWidth = window.innerWidth;
            },
            changeDrawerMode() {
                if (this.mediumScreen) {
                    this.mini = true;
                } else {
                    return;
                }
            }
        },
        mounted () {
            this.onResize()
        },
        watch: {
            $route(to, from) {
                if (to.name === 'create') {
                    setTimeout(() => {
                        if (from.path.includes('dynamic') || (from.name === 'dashboard' && !this.hasDynamicCodes)) {
                            EventBus.$emit('expandDynamic');
                        }
                        if (from.path.includes('static')) EventBus.$emit('highlightStatic');
                    }, 0);
                }
            },
            isLoggedIn() { this.onLogin() },
            'windowWidth'(newVal) {
                if (newVal >= 960 && newVal <= 1680) {
                    this.mini = true;
                    this.mediumScreen = true;
                } else {
                    this.mini = false;
                    this.mediumScreen = false;
                }
            },
        }
    }
</script>

<style lang="scss">
.container {
    height: 100%;
}
.mobile-menu {
    z-index: 9999 !important;
}
.smfm-pro-sidebar {
    background-color: #21252C !important;
    z-index: 999;
    .v-list-item {
        border-radius: 12px;
        &:before {
            border-radius: 12px;
        }
        &:hover {
            &:before {
                opacity: .1;
            }
        }
    }
    .v-divider {
        border-color: #393E46 !important;
    }
    .smfm-primary-btn {
        height: 64px !important;
        .v-btn__content {
            justify-content: flex-start;
        }
        .plus-wrap {
            width: 44px;
            height: 44px;
            background: #21252C;
            color: #fff;
            border-radius: 50%;
            line-height: 44px;
        }
    }
    .user-menu {
        background: #393E46;
        color: #fff;
    }
}
</style>
