<template>
  <v-dialog
    v-model="showDialog"
    max-width="500">
    <v-card class="notifications-dialog">
      <v-card-text class="d-flex flex-column align-center pt-7 pb-10">
        <div class="d-flex justify-center align-center icon-wrap"
          :class="[type === 'success' ? 'success' : 'error']">
          <v-icon :color="type === 'success' ? 'primary' : 'error'">
            {{ type === 'success' ? iconCheck : iconClose }}
          </v-icon>
        </div>
        <div class="smfm-page-heading mt-5">{{ title }}</div>
        <div class="subtitle mb-5">{{ subtitle }}</div>
        <v-card-actions class="d-flex justify-center align-center pa-0">
            <v-btn x-large :color="type === 'success' ? 'primary' : 'error'" @click="closeDialog">
                {{ buttontext }}
            </v-btn>
        </v-card-actions>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
    import EventBus from '@/plugins/EventBus.js';
    import { mdiClose, mdiCheck } from '@mdi/js';

    export default {
        name: "NotificationsDialog",
        props: {
            dialogVisible: {
                type: Boolean,
                default: false,
            }
        },
        data() {
            return {
                type: '',
                title: '',
                subtitle: '',
                buttontext: ''
            }
        },
        computed: {
            iconClose() { return mdiClose },
            iconCheck() { return mdiCheck },
            showDialog: {
                get() {
                    return this.dialogVisible;
                },
                set(newValue) {
                    if(!newValue) this.$emit('close');
                }              
            },
        },
        methods: {
            closeDialog() {
                this.showDialog = false;
            },
        },
        mounted() {
            EventBus.$on('showNotification', (data) => {
                this.type = data.type;
                this.title = data.title;
                this.subtitle = data.subtitle;
                this.buttontext = data.buttontext;
            })
        },
        beforeDestroy() {
            EventBus.$off('showNotification');
        }
    }
</script>

<style scoped lang="scss">
.notifications-dialog {
  .icon-wrap {
    width: 62px;
    height: 62px;
    border: 2px solid;
    border-radius: 50%;
    background-color: transparent !important;
    &.success {
      border-color: #03949A !important;
    }
    &.error {
      border-color: #C9454D !important;
    }
  }
  .subtitle {
    font-size: 16px;
    line-height: 24px;
    color: #909295;
  }
  .v-btn {
    width: 150px;
    height: 48px;
  }
}
</style>