<template>
    <div class="mb-7">
        <div v-html="label" class="mb-3"></div>
        <v-radio-group v-if="!fixedDisplayType" v-model="displaytype" dense @change="displayTypeChanged">
            <v-radio value="list" :label="$t('pro.forms.viewfileslist')"></v-radio>
            <v-radio value="thumb" :label="$t('pro.forms.viewfilesthumbs')"></v-radio>
        </v-radio-group>
        <div class="d-flex align-center">
            <VueFileAgent dir="ltr"
                ref="vueFileAgent"
                class="file-agent"
                :class="{'smfm-w-100': maxFiles > 1, 'not-round': isPageWithFilesLogo}"
                :key="agentKey"
                :theme="displaytype"
                :multiple="maxFiles > 1"
                :deletable="maxFiles > 1"
                :meta="displaytype === 'list'"
                :accept="'image/*,.pdf,.mp3,.docx,.xlsx,.txt'"
                :maxSize="'10MB'"
                :maxFiles="maxFiles"
                :helpText="$t('pro.forms.choosefiles')"
                :sortable="sortable && maxFiles > 1 ? 'handle' : false"
                :editable="editable && displaytype === 'list'"
                :averageColor="false"
                :errorText="{
                type: $t('pro.forms.invalidfiletype'),
                size: $t('pro.forms.filesizelimit', {size: '10MB'}),
                }"
                @select="filesSelected($event)"
                @beforedelete="onBeforeDelete($event)"
                @delete="deleteUploadedFile($event)"
                @sort="sorted"
                @rename="renamed"
                v-model="fileRecords">
            </VueFileAgent>
            <div v-if="maxFiles === 1">
                <v-btn v-if="fileRecords.length"
                    color="error" text
                    @click="removeSingle()">
                    <v-icon class="me-2">{{ iconDelete }}</v-icon>
                    Delete
                </v-btn>
            </div>
        </div>
    </div>
</template>

<script>
    import { mdiDelete } from '@mdi/js';
    import userMixin from "@/mixins/userMixin";
    //import {FileRecord} from "vue-file-agent";

    export default {
        name: "InputFiles",
        mixins: [ userMixin ],
        props: {
            filearea: null,
            codeid: null,
            maxFiles: { default: 100 },
            files: null,
            value: { default: 'list'},
            fixedDisplayType: { default: false },
            label: null,
            manageurl: null,
            sortable: { default: true },
            editable: { default: true },
            isPageWithFilesLogo: { default: false },
        },
        data() {
            return {
                displaytype: 'list',
                fileRecords: [],
                updatingSequence: false,
                needsUpdatingSequence: false,
                uploadedFiles: [],
                agentKey: 0,
            }
        },
        computed: {
            apiurl() {
                return this.manageurl ? this.manageurl : `pro/codes/${this.codeid}/files/${this.filearea}`
            },
            iconDelete() { return mdiDelete }
        },
        mounted() {
            this.displaytype = this.value
            this.refreshList()
        },
        methods: {
            displayTypeChanged() {
                this.$emit('input', this.displaytype)
                this.$emit('change')
            },
            getStoredFilename(fileRecord) {
                return fileRecord.file ? fileRecord.file.name : fileRecord.name()
            },
            findNewNameForFile(name, newnames) {
                const ext = name.split('.').pop()
                while (name in this.uploadedFiles || name in newnames) {
                    let basename = name.substr(0, name.length - (ext.length + 1))
                    let matches = basename.match(/^(.*) \((\d+)\)$/)
                    name = matches ? `${matches[1]} (${parseInt(matches[2])+1}).${ext}` : `${basename} (1).${ext}`
                }
                return name
            },
            deleteUploadedFile(fileRecord) {
                let files = [{name: this.getStoredFilename(fileRecord)}]
                this.authPost({url: this.apiurl, params: {action: 'delete', files}})
                    .then(() => this.$emit('updated'))
            },
            filesSelected(fileRecordsNewlySelected) {
                let validFileRecords = fileRecordsNewlySelected.filter((fileRecord) => !fileRecord.error);
                if (!validFileRecords.length) {
                    return
                }
                let newnames = {}
                validFileRecords.map(r => newnames[r.file.name] = this.findNewNameForFile(r.file.name, Object.values(newnames)))
                let files = validFileRecords.map(record => ({name: newnames[record.file.name], type: record.file.type}))
                this.authPost({url: this.apiurl, params: {action: 'upload', files}})
                    .then(resp => {
                        this.$refs.vueFileAgent.upload(
                            resp.data.uploadurl,
                            {},
                            validFileRecords.filter(record => newnames[record.file.name] in resp.data.uploadfields),
                            fileRecord => {
                                let fields = resp.data.uploadfields[newnames[fileRecord.file.name]]
                                let formData = new FormData();
                                Object.keys(fields).map(key => formData.append(key, fields[key]))
                                formData.append('file', fileRecord.file);
                                return formData
                            }
                        ).then(events => {
                            events.map(e => {
                                let newname = newnames[e.fileRecord.file.name]
                                if (newname !== e.fileRecord.file.name) {
                                    const ext = e.fileRecord.file.name.split('.').pop()
                                    e.fileRecord.file = new File([e.fileRecord.file], newname, {type: e.fileRecord.file.type})
                                    e.fileRecord.customName = newname.substr(0, newname.length - (ext.length + 1))
                                    this.agentKey++ // Refresh the name in the list
                                }
                                this.uploadedFiles.push(newname)
                            })
                            this.$emit('updated')
                            this.updateFilesSequence()
                        })
                    })
            },

            onBeforeDelete(fileRecord) {
                this.$refs.vueFileAgent.deleteFileRecord(fileRecord);

                // var i = this.fileRecordsForUpload.indexOf(fileRecord);
                // if (i !== -1) {
                //     // queued file, not yet uploaded. Just remove from the arrays
                //     this.fileRecordsForUpload.splice(i, 1);
                //     var k = this.fileRecords.indexOf(fileRecord);
                //     if (k !== -1) this.fileRecords.splice(k, 1);
                // } else {
                //     if (confirm('Are you sure you want to delete?')) {
                //         this.$refs.vueFileAgent.deleteFileRecord(fileRecord); // will trigger 'delete' event
                //     }
                // }
            },
            // fileDeleted: function (fileRecord) {
            //     var i = this.fileRecordsForUpload.indexOf(fileRecord);
            //     if (i !== -1) {
            //         this.fileRecordsForUpload.splice(i, 1);
            //     } else {
            //         this.deleteUploadedFile(fileRecord);
            //     }
            // },
            updateFilesSequence() {
                // Lock this function so only one can be running at a given time
                if (this.updatingSequence) {
                    this.needsUpdatingSequence = true
                    return
                }
                this.updatingSequence = true
                let files = this.fileRecords.map(record => this._prepareToStore(record)) // TODO filter uploaded (record.upload.data==="")
                this.$emit('updated')
                return this.authPost({url: this.apiurl, params: {action: 'update', files}})
                    .finally(() => {
                        this.updatingSequence = false
                        if (this.needsUpdatingSequence) {
                            this.needsUpdatingSequence = false
                            this.updateFilesSequence()
                        }
                    })
            },
            _prepareToStore(fileRecord) {
                return {
                    name: this.getStoredFilename(fileRecord),
                    size: fileRecord.size,
                    type: fileRecord.type,
                    ext: fileRecord.ext,
                    customName: fileRecord.name(true)
                }
            },
            sorted() {
                // parameter ev has oldIndex and newIndex
                this.updateFilesSequence()
            },
            renamed() {
                this.updateFilesSequence()
            },
            refreshList() {
                let files = (this.files && this.files.length) ? this.files : []
                // for (let i in files) {
                //     files[i].url = this.apiUrl+"/"+files[i].url
                // }
                this.uploadedFiles = files.map(f => f.name)
                this.fileRecords = files
            },
            removeSingle() {
                let record = this.fileRecords[0]
                this.fileRecords = []
                this.deleteUploadedFile(record)
            },
            getFiles() {
                return JSON.parse(JSON.stringify(this.fileRecords.map(record => this._prepareToStore(record))))
            }
        },
        watch: {
            files() {
                this.refreshList()
            },
            value(v) {
                this.displaytype = v
            },
        }
    }
</script>

<style lang="scss">
.file-agent {
    overflow: hidden;
    // General styles
    .vue-file-agent {
        text-align: left;
        .file-input {
            z-index: 7;
        }
        // General
        &.grid-block-wrapper {
            box-shadow: none;
            border: none;
            .file-preview-wrapper {
                &.file-preview-new  {
                    border: 1px dashed #03949A;
                    background: rgba(204, 239, 240, 0.4);
                    svg {
                        fill: #03949A;
                        top: 12px;
                        height: 25px;
                    }
                    .help-text {
                        height: auto;
                        font-size: 14px;
                        line-height: 20px;
                        color: #03949A;
                    }
                    &:before {
                        background: transparent;
                    }
                }
                &:before {
                    background: transparent;
                }
            }
            .file-sortable-handle {
                background: transparent;
                z-index: 90;
            }
            .file-preview {
                .image-dimension, .file-size, .file-ext {
                    display: none;
                }
                .file-preview-overlay {
                    background: transparent !important;
                }
                .file-preview-img {
                    width: 100%;
                    object-fit: cover;
                }
            }
        }
        // Only for single uploading
        &.is-single {
            width: 140px;
            height: 140px;
            padding: 0;
            margin: 0 15px 5px 0;
            .file-preview-wrapper {
                min-width: 140px;
                padding: 0;
                border-radius: 50%;
                &.file-preview-new  {
                    .help-text {
                        padding: 0 20px;
                    }
                }
            }
            .file-preview {
                .thumbnail {
                    border-radius: 50%;
                }
                .file-preview-img {
                    width: 140px;
                    height: 140px;
                    border-radius: 4px;
                }
                .file-delete svg {
                    background: hsla(0, 0%, 100%, .90);
                    // border-radius: 50%;
                    color: black;
                }
            }
            .file-category-video-playable {
                .file-preview {
                    .file-preview-overlay {
                        background: #fff !important;
                    }
                }
            }
        }
        // Only for multiple uploading
        &.has-multiple {
            border: 1px solid #393E46;
            border-radius: 10px;
            .file-preview-wrapper {
                background: #F3F4F6;
                border-width: 0;
                border-radius: 4px;
                &.file-preview-new  {
                    border-radius: 10px;
                }
            }
            .file-preview {
                &:before {
                    left: 50px;
                    background: #F3F4F6 !important;
                }
                &.other-preview {
                    background: #8791A3 !important;
                }
                .file-preview-img {
                    height: 100%;
                    border-radius: 4px;
                }
                .file-name {
                    height: 50px;
                    line-height: 50px;
                    padding-left: 15px;
                    .file-name-text {
                        font-weight: 500;
                        color: #1E2530;
                        margin-left: 5px;
                    }
                }
                .file-delete {
                    width: 22px;
                    height: 22px;
                    background: #C9454D;
                    border-radius: 50%;
                    border: 2px solid #fff;
                    font-size: 14px;
                    text-align: center;
                    svg {
                        fill: #fff;
                        vertical-align: unset;
                    }
                }
                .file-icon {
                    width: 50px;
                }
            }
        }
    }
    // Only for list view
    &.theme-list {
        .vue-file-agent {
            &.has-multiple {
                padding: 20px;
                .file-sortable-handle {
                    top: 15px !important;
                    left: 15px !important;
                }
                .file-preview-wrapper {
                    padding: 5px;
                    &:not(:last-child) {
                        margin-bottom: 10px !important;
                    }
                }
                .file-preview {
                    height: 50px;
                    .file-preview-img {
                        width: 50px;
                        height: 50px;
                    }
                    .file-delete {
                        top: 15px;
                        right: 15px;
                        border-color: transparent;
                    }
                }
            }
        }
    }
    // Only for grid view
    &.theme-thumb {
        .vue-file-agent {
            &.has-multiple {
                padding: 10px;
                .file-preview-wrapper {
                    width: 120px;
                    min-width: 120px;
                    height: 120px;
                    margin: 10px !important;
                    &.file-preview-new  {
                        svg {
                            margin-top: 0;
                        }
                    }
                }
                .file-preview {
                    &.other-preview {
                        border-radius: 4px;
                    }
                    .file-delete {
                        top: -9px;
                        right: -9px;
                    }
                }
            }
        }
    }
    &.not-round {
        .vue-file-agent {
            &.is-single {
                .file-preview {
                    .thumbnail {
                        border-radius: 0;
                    }
                    .file-preview-img {
                        object-fit: contain;
                        border-radius: 4px;
                        background: #fff;
                    }
                }
            }
        }
    }
}
</style>