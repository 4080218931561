<template>
    <div class="edit-dynamic">
        <v-overlay v-if="isloading"></v-overlay>
        <v-row v-if="data.shortname">
            <v-col md="12" lg="8" cols="12" class="smfm-pro-cards ps-0">
                <v-card flat :tile="$vuetify.breakpoint.smAndDown" class="pa-5 mb-4">
                    <div class="mb-7">
                        <pro-editable-name v-model="qrlabel"
                            :codeid="$route.params.codeid"
                            @change="e=>qrlabel=e">
                            <template>
                                <v-btn text color="primary"
                                       :to="{ name: 'codestats', params: {codeid} }">
                                    <v-icon color="primary" class="me-3">{{ iconChart }}</v-icon>
                                    {{ $t('pro.qrlist.statistics') }}
                                </v-btn>
                            </template>
                        </pro-editable-name>
                    </div>
                    <v-alert color="success" class="pa-5 mb-7">
                        <div class="d-flex flex-wrap justify-space-between align-center">
                            <div class="d-flex align-center">
                                <div class="d-flex justify-center align-center info-icon-wrap me-4">
                                    <v-icon color="white">{{ iconInfo }}</v-icon>
                                </div>
                                {{ shorturl }}
                                <a :href="shorturl+'?notracking=1'" target="_blank" class="ms-2">
                                    <v-icon color="white">{{ iconOpenLink }}</v-icon>
                                </a>
                            </div>
                            <pro-domain-selector :value="shorturl" 
                                :codeid="codeid"
                                @change="loadCode"
                                :class="[$vuetify.breakpoint.smAndDown ? 'ms-auto' : 'ms-3']">
                            </pro-domain-selector>
                        </div>
                    </v-alert>
                    <div>
                        <span v-html="prepareTextWithLinks(getArticleFromToc('about_dynamic_'+data.shortname, null, {}).summary)"></span>
                        <article-link :direct-link="true" :article="`about_dynamic_${data.shortname}`">
                            {{ $t('app.readmore') }}
                        </article-link>
                    </div>
                </v-card>

                <v-card flat :tile="$vuetify.breakpoint.smAndDown" class="pa-5 mb-4">
                    <div v-html="data.pagesubheader" class="font-weight-medium smfm-pro-heading mb-7"></div>
                    <pseudo-form ref="qrform">
                        <component v-bind:is="'dynamic-form-' + data.shortname" ref="miniform" 
                            :codeid="codeid" @change="saveDynamicForm">
                        </component>
                        <div v-if="data.description" class="pt-4" v-html="prepareTextWithLinks(data.description)"
                             :key="`description_${resourcesTocLoaded}`"></div>
                    </pseudo-form>
                </v-card>
                <!-- QR styles -->
                <v-card flat :tile="$vuetify.breakpoint.smAndDown" class="pa-5 mb-4">
                    <qrstyles @change="saveQrStyles" ref="qrstyles" :expanded="qrstylesExpanded"></qrstyles>
                </v-card>
            </v-col>
            <!-- QR preview -->
            <v-col md="12" lg="4" cols="12" class="smfm-pro-cards pe-0">
                <div class="demo-image-container smfm-sticky-md-16">
                    <v-tabs v-model="tab" centered center-active height="70" class="preview-tabs">
                        <v-tab v-for="tab in tabs" :key="tab.type">
                            <span style="text-transform: none">{{ tab.title }}</span>
                        </v-tab>
                    </v-tabs>
                    <v-tabs-items v-model="tab" class="mb-4">
                        <v-tab-item v-for="t in tabs" :key="t.type">
                            <v-card flat :min-height="400">
                                <v-card-text :class="{'preview-tab': t.type !== 'qr' && data.shortname !== 'url'}">
                                    <qr-preview v-if="t.type === 'qr'"
                                        :iconname="data.shortname" ref="qrpreview">
                                    </qr-preview>
                                    <div v-else>
                                        <iframe v-if="data.shortname !== 'url'"
                                            :src="iframeurl"
                                            id="preview"
                                            width="100%"
                                            frameborder="0"
                                            webkitallowfullscreen
                                            mozallowfullscreen
                                            allowfullscreen
                                            class="page-preview">
                                        </iframe> 
                                        <div v-else class="d-flex flex-column justify-center align-center no-preview">
                                            <div class="d-flex justify-center align-center no-preview-icon">
                                                <v-icon color="primary">{{ iconInfo }}</v-icon>
                                            </div>
                                            <div class="smfm-page-subheading text-center">
                                                {{ $t('pro.qrlist.previewnotavailable') }}
                                            </div>
                                        </div>
                                    </div>                              
                                </v-card-text>
                            </v-card>
                        </v-tab-item>
                    </v-tabs-items>
                    <pro-qr-download-button :qrlabel="qrlabel" :codes="[currentqr]" v-if="tabs[tab].type==='qr'">
                    </pro-qr-download-button>
                </div>
            </v-col>
        </v-row>
        <v-alert v-if="invalidcode" type="error" v-html="$t('pro.codenotfound')">
            <!-- TODO different messages for different errors? -->
        </v-alert>
    </div>
</template>

<script>
    import { mapActions, mapGetters } from "vuex";
    import { mdiOpenInNew, mdiInformationOutline, mdiChartBar } from '@mdi/js';
    import qrMixin from '@/mixins/qrMixin';
    import qrdownload from "@/mixins/qrdownload";
    import userMixin from "@/mixins/userMixin";
    import customIcons from "@/mixins/customIcons";
    import randKey from "@/mixins/randKey";
    import PseudoForm from "@/components/forms/PseudoForm";
    import Qrstyles from "@/components/forms/Qrstyles";
    import QrPreview from "@/components/demo/QrPreview";
    import ProEditableName from "@/components/pro/edit/ProEditableName";
    import ProDomainSelector from "@/components/pro/edit/ProDomainSelector";
    import ProQrDownloadButton from "@/components/pro/download/ProQrDownloadButton";
    import ArticleLink from "@/components/forms/ArticleLink";

    export default {
        name: "ProEditDynamic",
        mixins: [ qrMixin, userMixin, customIcons, qrdownload, randKey ],
        components: {
            ArticleLink,
            ProQrDownloadButton,
            ProDomainSelector,
            ProEditableName, 
            QrPreview,
            Qrstyles, 
            PseudoForm,
        },
        data() {
            return {
                data: {},
                isloading: false,
                shorturl: '',
                invalidcode: false,
                qrstylesExpanded: null,
                tab: 0,
                qrlabel: '',
                previewrev: 0,
                iconChart: mdiChartBar
            }
        },
        computed: {
            ...mapGetters({currentqr: 'getCurrentQr'}),
            ...mapGetters(['resourcesTocLoaded']),
            codeid() { return this.$route.params.codeid },
            iconOpenLink() { return mdiOpenInNew },
            iconInfo() { return mdiInformationOutline },
            tabs() {
                return [
                    { type: 'qr', title: this.$t('app.generatedqr') },
                    { type: 'preview', title: this.$t('pro.previewpage') }
                ]
            },
            iframeurl() {
                let url = `${this.shorturl}`;
                if (`${process.env.VUE_APP_API}`.startsWith('https://')) {
                    url = url.replace(/^http:\/\//, 'https://');
                }
                return `${url}?notracking=1&rev=${this.randKey}-${this.previewrev}`;
            }
        },
        created() {
            // TODO check codeid format (must start with 'D-')
            this.loadCode()
        },
        methods: {
            ...mapActions(['setCurrentQr', 'resetCurrentQr']),
            loadCode() {
                this.isloading = true
                this.resetCurrentQr()
                let data
                this.authGet({url: 'pro/codes/' + this.codeid})
                    .then(res => {
                        data = res.data
                        this.qrlabel = data.name
                        this.shorturl = data.text
                        let cards = this.dynamiccards[this.userLang]
                        const filtered = cards.filter(a => a.shortname === data.type)
                        this.data = filtered.length ? filtered[0] : {shortname: data.type}
                        // If styles were never set for this QR code, expand the first (0) section of the qrstyles - "Presets":
                        if (!data.config.style) this.qrstylesExpanded = 0
                        this.$nextTick(() => {
                            this.$refs.miniform.value = data.value
                            this.$refs.miniform.files = data.files
                            this.$refs.qrstyles.setConfig(data.config)
                        })
                        //this.lastvalue = {data: data.text}
                        return this.authGet({url: data.qrurl})
                    })
                    .then(resp => {
                        this.setCurrentQr({
                            image: resp.data,
                            codeid: this.codeid,
                            qrurl: data.qrurl,
                            qrlabel: data.name,
                        })
                    })
                    .catch(() => this.invalidcode = true)
                    .finally(() => this.isloading = false)
            },
            saveQrStyles() {
                if (!this.$refs.qrform.form.value) { return } // Not validated.
                this.refreshQrPro(this.saveCode(), this.shorturl)
                this.$refs.qrform.submit()
            },
            saveDynamicForm() {
                if (!this.$refs.qrform.form.value) { return } // Not validated.
                this.authPatch({url: 'pro/codes/'+this.$route.params.codeid, params: {value: this.$refs.miniform.value}})
                    .then(() => this.refreshPagePreview())
                    .catch(e => console.error(e))
                this.$refs.qrform.submit()
            },
            refreshPagePreview() {
                if (document.getElementById('preview')) {
                    this.previewrev++;
                    document.getElementById('preview').src = this.iframeurl;
                }
            },
            saveCode() {
                const obj = {
                    config: JSON.parse(JSON.stringify(this.$refs.qrstyles.config)),
                }
                return this.authPost({url: 'pro/codes/'+this.$route.params.codeid, params: obj})
                    .catch(e => console.error(e))
            }
        },
    }
</script>

<style scoped lang="scss">
.edit-dynamic {
    .info-icon-wrap {
        width: 50px;
        height: 50px;
        border: 1px solid #FFFFFF;
        border-radius: 50%;
    }
    .v-alert {
        color: #fff;
    }
    .description {
        max-width: 800px;
        color: #393E46;
    }
    .preview-tabs {
        .v-tab {
            width: 50% !important;
        }
    }
    .v-card__text {
        &.preview-tab {
            height: 700px;
            padding-left: 0;
            padding-right: 0;
            > div,
            .page-preview {
                height: 100%;
            }
        }
    }
    .no-preview {
        min-height: 368px;
        .no-preview-icon {
            width: 64px;
            height: 64px;
            border-radius: 50%;
            background: rgba(204, 239, 240, 0.5);
            margin-bottom: 20px;
        }
        .smfm-page-subheading {
            max-width: 320px;
            color: #909295;
        }
    }
}
</style>
