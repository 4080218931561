<template>
    <span class="domain-selector">
        <v-btn dark class="medium" @click="openDialog">{{ $t('pro.shorturl.changebtn') }}</v-btn>

        <simple-dialog persistent max-width="600px" ref="dialog"
            :header="$t('pro.shorturl.changeshorturl')" :no-title="true"
            @save="applyChange">
            <v-row>
                <v-col sm="6" class="py-0 ps-0">
                    <v-select outlined :items="domainsOptions" :label="$t('pro.shorturl.domain')" v-model="domain"
                        @input="checkPath">
                    </v-select>
                </v-col>
                <v-col sm="6" class="py-0 pe-0">
                    <v-text-field outlined :label="$t('pro.shorturl.path')" v-model="pathname" dir="ltr"
                        @input="checkPath">
                    </v-text-field>
                </v-col>
            </v-row>
            <div v-if="pathValidated && !hasNoChanges" class="mb-3">
                <v-icon :color="pathcolor">{{ pathicon }}</v-icon>
                <span v-if="pathValid">{{ $t('pro.shorturl.available') }}</span>
                <span v-else v-html="error"></span>
            </div>
            <div v-html="$t('pro.shorturl.description')">
            </div>

            <template v-slot:buttons="{save}">
                <v-btn class="smfm-primary-btn smfm-lg-btn"
                    :disabled="!pathValidated || !pathValid || hasNoChanges"
                    @click="save">
                    {{ $t('pro.shorturl.changebtn') }}
                </v-btn>
            </template>
        </simple-dialog>
        <v-overlay v-if="saving"></v-overlay>
    </span>
</template>

<script>
    import {mapGetters} from "vuex";
    import {mdiCheck, mdiClose} from '@mdi/js';
    import SimpleDialog from "@/components/forms/SimpleDialog";
    import userMixin from "@/mixins/userMixin";

    export default {
        name: "ProDomainSelector",
        components: {SimpleDialog},
        mixins: [userMixin],
        props: {
            value: null,
            codeid: null,
        },
        data() {
            return {
                domain: null,
                autopath: true,
                pathname: null,
                iconAvailable: mdiCheck,
                iconUnavailable: mdiClose,
                pathValidated: false,
                pathValid: false,
                originalvalue: null,
                error: '',
                saving: false,
            }
        },
        computed: {
            ...mapGetters(['availableDomains']),
            domainsOptions() {
                let res = []
                for (let record of this.availableDomains) {
                    if (record.https) {
                        //res.push(`https://${record.domain}/`)
                        res.push({text: `https://${record.domain}/`, value: `https://${record.domain}`})
                    }
                    if (record.http) {
                        //res.push(`http://${record.domain}/`)
                        res.push({text: `http://${record.domain}/`, value: `http://${record.domain}`})
                    }
                }
                return res
            },
            pathicon() {
                if (this.pathValidated) {
                    return this.pathValid ? this.iconAvailable : this.iconUnavailable
                }
                return null
            },
            pathcolor() {
                if (this.pathValidated) {
                    return this.pathValid ? 'success' : 'error'
                }
                return null
            },
            url() {
                return `${this.domain}/${this.pathname}`
            },
            hasNoChanges() {
                return this.url === this.originalvalue
            }
        },
        methods: {
            resetDomain() {
                this.domain = this.domainsOptions[0].value
                this.autopath = true
                this.pathValidated = false
            },
            preCheck() {
                if (this.url.toLowerCase().replace(/^https?:/, '') ===
                    this.originalvalue.toLowerCase().replace(/^https?:/, '')) {
                    return true
                }
                if (this.pathname.length < 6) {
                    return this.$t('app.validation.pathminlength', {num: 6})
                }
                const pathname = this.pathname.toLowerCase()
                const extrachars = pathname.toLowerCase().replace(/[a-z0-9_\\-]/g, '')
                if (extrachars !== '') {
                    return this.$t('app.validation.pathcontain')
                }
                if (!pathname.match(/^[a-z0-9].*[a-z0-9]$/)) {
                    return this.$t('app.validation.pathstartend')
                }
                return ''
            },
            checkPath() {
                let precheck = this.preCheck()
                if (precheck === true) {
                    this.pathValid = true
                    this.pathValidated = true
                    return
                }
                if (precheck !== '') {
                    this.pathValid = false
                    this.pathValidated = true
                    this.error = precheck
                    return
                }
                this.pathValidated = false
                let currenturl = this.url
                this.authPost({url: 'pro/checkpath', params: {url: currenturl}})
                    .then(resp => {
                        if (this.url === currenturl) {
                            this.pathValidated = true
                            this.pathValid = resp.data.available
                            this.error = 'Not available'
                        }
                    })
            },
            openDialog() {
                this.originalvalue = this.value
                let url = new URL(this.value)
                this.domain = url.origin
                this.pathname = url.pathname.replace(/^\//, '')
                this.pathValid = true
                this.$refs.dialog.open()
                //console.log(`opened ${this.originalvalue} -> ${this.url} (${this.hasNoChanges})`)
            },
            applyChange() {
                this.saving = true
                return this.authPost({url: 'pro/codes/'+this.codeid, params: {shorturl: this.url}})
                    .then(() => {
                        this.$emit('input', this.url)
                        this.$emit('change')
                    })
                    .catch(e => {
                        // TODO display some info that renaming was not possible (probably race condition
                        // and the url is no longer available).
                        console.error(e)
                    })
                    .finally(() => {
                        this.saving = false
                    })
            }
        },
    }
</script>

<style scoped lang="scss">
.domain-selector {
    .smfm-md-btn {
        width: 140px;
    }
    .v-select__selections input { 
        width: 20px;
    }
}
</style>