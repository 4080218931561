import Vue from 'vue'
import VueRouter from 'vue-router'
import axios from 'axios'
import ProDashboard from "@/components/pro/dashboard/ProDashboard";
import ProListDynamic from "@/components/pro/lists/ProListDynamic";
import ProListStatic from "@/components/pro/lists/ProListStatic";
import ProCreate from "@/components/pro/create/ProCreate";
import ProCreateStatic from "@/components/pro/create/ProCreateStatic";
import ProEditDynamic from "@/components/pro/edit/ProEditDynamic";
import ProLibrary from "@/components/pro/library/ProLibrary";
import ProEditStatic from "@/components/pro/edit/ProEditStatic";
import ProCodeStats from "@/components/pro/ProCodeStats";
import ProApi from "@/components/pro/api/ProApi";
import ProSupport from "@/components/pro/ProSupport";
import ProAccount from "@/components/pro/settings/ProAccount";
import ProPassword from "@/components/pro/settings/ProPassword";
import ProPreferences from "@/components/pro/settings/ProPreferences";
import ProSettings from "@/components/pro/settings/ProSettings";
import ProSubscription from "@/components/pro/settings/ProSubscription";
import store from "../store/index-pro";

Vue.use(VueRouter, axios)

const routes = [
    { path: '/', name: 'dashboard', component: ProDashboard, meta: { skipActivePlan: true }  },
    // Login/logout
    { path: '/login_callback', name: 'loginCallback', meta: { skiplogin: true, skipActivePlan: true } },
    { path: '/logout', name: 'logout', meta: { skiplogin: true, skipActivePlan: true } },
    // Dynamic
    { path: '/dynamic', name: 'dynamic', component: ProListDynamic },
    { path: '/dynamic/:codeid', component: ProCodeStats, name: 'codestats' },
    { path: '/dynamic/:codeid/edit', component: ProEditDynamic, name: 'editdynamic' },
    // Static
    { path: '/static', name: 'static', component: ProListStatic },
    { path: '/static/:codeid', component: ProEditStatic, name: 'editstatic' },
    { path: '/createstatic/:slug', component: ProCreateStatic, name: 'createstatic' },
    // Other
    { path: '/create', name: 'create', component: ProCreate },
    { path: '/library', name: 'library', component: ProLibrary },
    { path: '/api', name: 'api', component: ProApi },
    { path: '/support', name: 'support', component: ProSupport, meta: { skipActivePlan: true } },
    // Settings
    { path: '/settings', name: 'settings', component: ProSettings, redirect: { name: "preferences" },
        children: [
            {
                path: "preferences",
                name: "preferences",
                component: ProPreferences
            },
            {
                path: "account",
                name: "account",
                component: ProAccount
            },
            {
                path: "password",
                name: "password",
                component: ProPassword
            },
            {
                path: "subscription",
                name: "subscription",
                component: ProSubscription
            },
        ]
    },
    // Profile
    { path: '/profile', component: ProSettings, name: 'profile' },
    { path: '/profile/payment_success', component: ProSubscription, name: 'payment_success' },
    { path: '/profile/payment_cancelled', component: ProSubscription, name: 'payment_cancelled' },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior() {
        return { x: 0, y: 0 }
    }
})

router.beforeEach(async(to, from, next) => {
    if (store.getters["user"]) {
        const planEnds = parseInt(store.getters["user"]['custom:planends']);
        const planRenews = parseInt(store.getters["user"]['custom:planrenews']);
        const curTime = Math.round((new Date().getTime())/1000);
        const planEnded = store.getters["user"]['custom:plan'] && !planRenews && planEnds && planEnds < curTime;
        if (!planEnded) {
            next();
            return;
        } else {
            if (to.matched.some(record => record.meta.skipActivePlan)) {
                next();
            } else {
                next('/');
            }
        }
    }
    // next();
});

export default router
