<template>
    <div class="create">
        <v-row>
            <v-col md="12" lg="8" cols="12" class="smfm-pro-cards ps-0 pb-4">
                <v-card flat :tile="$vuetify.breakpoint.smAndDown"
                    :class="[$vuetify.breakpoint.smAndDown ? 'pa-5' : 'pa-7']">
                    <v-overlay v-if="isloading"></v-overlay>
                    <div class="smfm-page-heading mb-5">{{ $t('pro.createnewdynamiccode') }}</div>
<!--                    <div class="smfm-pro-subtitle">-->
<!--                        Ultricies amet et sed a faucibus quis. Tincidunt placerat purus quam nulla ridiculus. Nec dui elit vel duis.-->
<!--                    </div>-->
                    <!-- Dynamic QR code -->
                    <v-card-text class="pa-0">
                        <v-expansion-panels accordion v-model="expandedpanel" ref="panels"
                            class="dynamic-codes">
                            <v-expansion-panel v-for="(data, idx) in dynamictypes" :key="'d'+idx">
                                <v-expansion-panel-header hide-actions>
                                    <div class="d-flex flex-wrap justify-space-between align-center text-body-1 font-weight-medium">
                                        <div class="d-flex align-center">
                                            <v-icon class="me-3">
                                                {{ getQrIcon('dynamic', data.shortname) }}
                                            </v-icon>
                                            {{ data.pageheader }}
                                            <article-link :article="'about_dynamic_'+data.shortname" class="ms-3" :show-title="true">
                                                <v-icon class="info-icon">{{ iconInfo }}</v-icon>
                                            </article-link>
                                        </div>
                                        <v-btn large class="smfm-exmp-btn d-sm-flex d-none" @click.prevent.stop="seeExample(data.shortname)">
                                            {{ $t('app.seeexample') }}
                                        </v-btn>
                                        <v-btn text small class="d-block d-sm-none ms-auto"
                                            @click.prevent.stop="seeExample(data.shortname)">
                                            {{ $t('app.seeexample') }}
                                        </v-btn>
                                    </div>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content eager class="pa-0">
                                    <pseudo-form :ref="'qrform'+data.shortname">
                                        <component v-bind:is="'dynamic-form-' + data.shortname" @change="isValid(data.shortname)"
                                                   :ref="'delement'+data.shortname"></component>
                                        <v-btn class="smfm-primary-btn smfm-md-btn" :disabled="!valid"
                                            @click="createDynamic(data.shortname)">
                                            {{$t('pro.continue')}}
                                        </v-btn>
                                    </pseudo-form>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col md="12" lg="4" cols="12" class="smfm-pro-cards pe-0">
            </v-col>
        </v-row>   
        <v-row>
            <v-col md="12" lg="8" cols="12" class="smfm-pro-cards ps-0 pb-4">
                <v-card flat :tile="$vuetify.breakpoint.smAndDown"
                    :class="[$vuetify.breakpoint.smAndDown ? 'pa-5' : 'pa-7']">
                    <!-- Static QR code -->
                    <div class="smfm-page-heading mb-5">{{ $t('pro.createnewstaticcode') }}
                        <article-link article="about_static" class="ms-3" :show-title="true">
                            <v-icon class="info-icon">{{ iconInfo }}</v-icon>
                        </article-link>
                    </div>
                    <v-card-text class="static-btns-grid pa-0">
                        <router-link v-for="(data, idx) in statictypes" :key="'s'+idx" :ref="'createstaticcode'+idx"
                            :to="{ name: 'createstatic', params: { slug: data.slug } }"
                            class="d-flex align-center static-btn pa-6"
                            :class="{'highlighted': data.shortname === 'url' && highlightUrl}">
                            <v-icon class="me-3">{{ getQrIcon('static', data.shortname) }}</v-icon>
                            <span class="text">{{ data.title }}</span>
                        </router-link>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col md="12" lg="4" cols="12" class="smfm-pro-cards pe-0">
            </v-col>
        </v-row>
        <!-- Example dialog -->
        <about-dynamic-popup ref="exampledialog"></about-dynamic-popup>
    </div>
</template>

<script>
    import { mdiInformation } from '@mdi/js';
    import userMixin from "@/mixins/userMixin";
    import customIcons from "@/mixins/customIcons";
    import EventBus from '@/plugins/EventBus.js';
    import PseudoForm from "@/components/forms/PseudoForm";
    import ArticleLink from "@/components/forms/ArticleLink";
    import AboutDynamicPopup from "@/components/about/AboutDynamicPopup";

    export default {
        name: "ProCreate",
        mixins: [userMixin, customIcons],
        components: {
            AboutDynamicPopup,
            ArticleLink, 
            PseudoForm,
        },
        data() {
            return {
                isloading: false,
                url: null,
                expandedpanel: null,
                iconInfo: mdiInformation,
                shortUrlDialogVisible: false,
                contactCardDialogVisible: false,
                pageWithFilesDialogVisible: false,
                highlightUrl: false,
                valid: false,
            }
        },
        computed: {
            dynamictypes() {
                return this.dynamiccards[this.userLang]
            },
            statictypes() {
                return this.staticcards[this.userLang].filter(v=>v.hasform)
            }
        },
        methods: {
            isValid(type) {
                if (this.$refs && this.$refs['qrform'+type]) {
                    const form = this.$refs['qrform'+type][0]
                    this.valid = form.valid
                    return form.valid
                }
            },
            createDynamic(type) {
                const form = this.$refs['qrform'+type][0]
                if (!form.form.validate()) {
                    return
                }
                const value = this.$refs['delement'+type][0].value, label = this.$refs['delement'+type][0].label
                form.submit()

                const obj = {
                    dynamic: true,
                    type: type,
                    name: label,
                    config: {},
                    value: JSON.parse(JSON.stringify(value))
                }
                this.isloading = true
                return this.authPut({url: 'pro/codes', params: obj})
                    .catch(e => console.error(e))
                    .then(res => this.$router.push(`/dynamic/${res.data.codeid}/edit`))
                    .finally(() => this.isloading = false)
            },
            seeExample(type) {
                this.$refs.exampledialog.showDialog(type)
            }
        },
        mounted() {
            EventBus.$on('expandDynamic', () => {
                // Event is triggered when we navigate to the 'create' page from the dynamic codes list.
                // We need to make sure that at least one section in the dynamic codes list is expanded and not highlight static urls.
                if (this.expandedpanel === null) {
                    this.expandedpanel = 0;
                }
                // Focus on the first input field of the expanded section.
                const type = this.dynamictypes[this.expandedpanel].shortname;
                const ref = this.$refs['delement'+type][0];
                if (ref && ref.focusMain) {
                    ref.focusMain();
                }
                // No longer highlight the static url section.
                this.highlightUrl = false;
            });
            EventBus.$on('highlightStatic', () => {
                // Event is triggered when we navigate to the 'create' page from the static codes list.
                // We need to close all dynamic code sections and highlight the static url section.
                this.expandedpanel = null;
                this.highlightUrl = true;
                this.$nextTick(() => {
                    if (this.$vuetify.breakpoint.smAndDown) this.$vuetify.goTo(this.$refs.createstaticcode4[0].$el.offsetTop);
                });
            });
        },
        beforeDestroy() {
            EventBus.$off('expandDynamic');
            EventBus.$off('highlightStatic');
        },
        beforeRouteLeave(to, from, next) {
            this.highlightUrl = false;
            next();
        }
    }
</script>

<style scoped lang="scss">
.create {
    .v-expansion-panel {
        border: 1px solid #EEEEEE;
        border-radius: 16px;
        margin-bottom: 10px;
        &:hover,
        &.v-expansion-panel--active {
            border-color: #21252C;
        }
    }
    .v-expansion-panel-header {
        height: 80px;
        background-color: #fff !important;
        color: #393E46;
        padding: 28px 22px;
        border-radius: 16px;
        .smfm-exmp-btn {
            color: #393E46;
            border-color: #393E46;
        }
        .v-btn--text {
            padding-inline-end: 0 !important;
            color: #393E46;
            text-decoration: underline;
        }
        &.v-expansion-panel-header--active {
            min-height: 80px;
            background-color: #21252C !important;
            color: #fff;
            border-radius: 16px 16px 0px 0px;
            .v-icon {
                color: #fff !important;
            }
            .smfm-exmp-btn {
                color: #fff;
                border-color: #fff;
            }
            .v-btn--text {
                color: #fff;
            }
        }
    }
    .v-expansion-panels {
        .v-expansion-panel:not(:first-child) {
            &:after {
                border-color: transparent;
            }
        }
    }
    .smfm-md-btn {
        width: 150px;
    }
    .static-btns-grid {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(290px, 1fr));
        grid-gap: 15px;
        justify-items: center;
    }
    .static-btn {
        width: 290px;
        height: 70px;
        border: 1px solid #EEEEEE;
        border-radius: 12px;
        text-decoration: none;
        cursor: pointer;
        .text {
            font-size: 16px;
            line-height: 24px;
            color: #393E46;
        }
        &.highlighted {
            background: #00ADB5 !important;
            .text, .v-icon {
                color: #fff !important;
            }
        }
    }
}
</style>
