<template>
    <div class="api">
        <v-row>
            <v-col md="12" lg="8" cols="12" class="smfm-pro-cards ps-0 pb-4">
                <!-- API keys -->
                <v-card flat :tile="$vuetify.breakpoint.smAndDown" class="mb-4"
                    :class="[$vuetify.breakpoint.smAndDown ? 'pa-5' : 'pa-7']">
                    <div class="smfm-page-heading mb-5">{{ $t('pro.api.apikeys') }}</div>
                    <div class="smfm-pro-subtitle mb-5" v-html="$t('pro.api.descriptionapikeys')">
                    </div>
                    <loading v-if="apikeys === null" :size="2" class="my-10"></loading>
                    <div v-else-if="!apikeys.length" class="text-body-2 secondary--text">
                        {{ $t('pro.api.nokeys') }}
                    </div>
                    <div v-else>
                        <v-data-table
                            :headers="apikeysheaders"
                            :options="{sortBy: ['timecreated']}"
                            :items="apikeys"
                            hide-default-footer
                            class="smfm-data-table"
                            mobile-breakpoint="0">
                            <template v-slot:[`item.apikey`]="{ item }">
                                <span class="font-weight-medium">{{ item.apikey }}</span>
                                <copy-to-clipboard :value="item.apikey"></copy-to-clipboard>
                            </template>
                            <template v-slot:[`item.timecreated`]="{ item }">
                                {{ formatDate(item.timecreated) }}
                            </template>
                            <template v-slot:[`item.comment`]="{ item, index }">
                                <v-edit-dialog :return-value.sync="v"
                                    @save="updateApiKeyComment(item.codeid, index)"
                                    @open="v=item.comment">
                                    <loading v-if="loading[index]"></loading>
                                    <div v-else>
                                        <span v-if="item.comment">
                                            {{ item.comment }}
                                        </span>
                                        <span v-if="!item.comment" class="placeholder">
                                            {{ $t('pro.api.addcomment') }}
                                        </span>
                                    </div>
                                    <template v-slot:input>
                                        <v-text-field class="mb-3" v-model="v" :label="$t('pro.api.addcomment')"></v-text-field>
                                    </template>
                                </v-edit-dialog>
                            </template>
                            <template v-slot:[`item.actions`]="{ item }">
                                <simple-dialog :header="$t('pro.api.delete')" :no-title="true"
                                    @save="deleteApiKey(item.codeid)">
                                    <template v-slot:activator="{open}">
                                        <v-btn icon @click="open">
                                            <v-icon color="#C9454D">{{ iconDelete }}</v-icon>
                                        </v-btn>
                                    </template>
                                    <div class="text--primary">
                                        {{ $t('pro.api.confirmdelete') }}
                                    </div>
                                    <template v-slot:buttons="{save}">
                                        <v-btn color="error" @click="save">{{ $t('pro.api.delete') }}</v-btn>
                                    </template>
                                </simple-dialog>
                            </template>
                        </v-data-table>
                    </div>
                    <v-btn class="smfm-primary-btn mt-3"
                        @click="createApiKey">
                        {{ $t('pro.api.createnewkey') }}
                    </v-btn>
                </v-card>
                <!-- Temporary files -->
                <v-card flat :tile="$vuetify.breakpoint.smAndDown"
                    :class="[$vuetify.breakpoint.smAndDown ? 'pa-5' : 'pa-7']">
                    <div class="d-flex justify-space-between align-center">
                        <div class="smfm-page-heading mb-5">{{ $t('pro.api.tempfiles') }}</div>
                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                            <v-btn v-bind="attrs" v-on="on"
                                x-small outlined fab
                                @click="onRefresh">
                                <v-icon>{{ iconRefresh }}</v-icon>
                            </v-btn>
                            </template>
                            <span>{{ $t('pro.refresh') }}</span>
                        </v-tooltip>
                    </div>
                    <pro-temp-files></pro-temp-files>
                </v-card>
            </v-col>
            <v-col md="12" lg="4" cols="12" class="smfm-pro-cards pe-0 pb-4"
                :order="$vuetify.breakpoint.mdAndDown ? 'first' : 'last'">
                <!-- API -->
                <v-card flat :tile="$vuetify.breakpoint.smAndDown"
                    :class="[$vuetify.breakpoint.smAndDown ? 'pa-5' : 'pa-7']">
                    <div class="smfm-page-heading mb-5">{{ $t('pro.api.quickreference') }}</div>
                    <div class="text-body-2 secondary--text">
                        {{ $t('pro.api.endpoint') }}:
                    </div>
                    <div class="mb-5">
                        <span class="font-weight-medium">{{ apiUrl }}</span>
                    </div>
                    <div class="text-body-2 secondary--text">
                        {{ $t('pro.api.commands') }}:
                    </div>
                    <v-row class="mb-5">
                        <v-col cols="12" sm="6" md="6" lg="6" class="px-0">
                            <span class="font-weight-medium">/v1/create</span>
                        </v-col>
                        <v-col cols="12" sm="6" md="6" lg="6" class="px-0">
                            GET, POST
                        </v-col>
                        <v-col cols="12" sm="12" md="12" lg="12" class="px-0" v-html="$t('pro.api.createdesc')">
                        </v-col>
                        <v-col cols="12" sm="6" md="6" lg="6" class="px-0">
                            <span class="font-weight-medium">/v1/uploadfile</span>
                        </v-col>
                        <v-col cols="12" sm="6" md="6" lg="6" class="px-0">
                            POST
                        </v-col>
                        <v-col cols="12" sm="12" md="12" lg="12" class="px-0" v-html="$t('pro.api.uploaddesc')">
                        </v-col>
                    </v-row>
                    <div class="text-body-2 secondary--text">
                        {{ $t('pro.api.documentation') }}:
                    </div>
                    <a href="https://scanmefindme.com/en/api" target="_blank" class="smfm-externallink">scanmefindme.com/en/api</a>
                </v-card>
            </v-col>
        </v-row>
        <v-snackbar v-model="haserror" color="error" timeout="10000">
            {{ errortext }}
            <template v-slot:action="{ attrs }">
                <v-btn color="gray" text v-bind="attrs" @click="setError(null)">{{ $t('app.close') }}</v-btn>
            </template>
        </v-snackbar>   
    </div>
</template>

<script>
    import { mdiKeyChain, mdiFileClockOutline, mdiDelete } from '@mdi/js';
    import userMixin from "@/mixins/userMixin";
    import ProTempFiles from "@/components/pro/api/ProTempFiles";
    import CopyToClipboard from "@/components/forms/CopyToClipboard";
    import SimpleDialog from "@/components/forms/SimpleDialog";
    import EventBus from '@/plugins/EventBus.js';
    import Loading from "@/components/common/Loading";
    import customIcons from "@/mixins/customIcons";

    export default {
        name: "ProLibrary",
        mixins: [ userMixin, customIcons ],
        components: { SimpleDialog, CopyToClipboard, ProTempFiles, Loading },
        data() {
            return {
                errortext: null,
                haserror: false,
                panel: [0, 1],
                iconFiles: mdiFileClockOutline,
                apikeys: null,
                v: null,
                iconKeys: mdiKeyChain,
                iconDelete: mdiDelete,
                loading: {},
            }
        },
        computed: {
            apiUrl() { return process.env.VUE_APP_USERAPI },
            apikeysheaders() {
                return [
                    { value: 'apikey', text: this.$t('pro.api.apikey'), align: 'start'},
                    { value: 'comment', text: this.$t('pro.api.comment'), align: 'start'},
                    { value: 'usedcnt', text: this.$t('pro.api.timesused'), align: 'center'},
                    { value: 'timecreated', text: this.$t('pro.api.created'), align: 'start'},
                    { value: 'actions', text: '', align: 'end', sortable: false},
                ]
            },
            loadingKeys() {
                return [...Array(this.apikeys.length).keys()]
            },
        },
        created() {
            this.retrieveKeys()
        },
        methods: {
            setError(t) {
                this.errortext = t
                this.haserror = !!t
            },
            retrieveKeys() {
                return this.authGet({url: 'pro/apikeys'})
                    .then(resp => {
                        this.apikeys = resp.data.apikeys
                        this.apikeysusage = resp.data.usage
                    })
            },
            createApiKey() {
                this.authPost({url: 'pro/apikeys'})
                    .then(resp => {
                        this.apikeys.push(resp.data)
                        return this.retrieveKeys()
                    })
            },
            deleteApiKey(codeid) {
                this.authDelete({url: `pro/apikeys/${codeid}`})
                    .then(() => this.retrieveKeys())
            },
            updateApiKeyComment(codeid, index) {
                this.loading[index] = true;
                this.authPatch({url: `pro/apikeys/${codeid}`, params: {comment: this.v}})
                    .then(() => {
                        this.retrieveKeys();
                        this.loading[index] = false;
                    })
            },
            onRefresh() {
                EventBus.$emit('refreshTempFiles');
            }
        },
    }
</script>

<style scoped lang="scss">
.api {
    .v-icon {
      color: #393E46;
    }
    .v-btn--fab {
      color: #eee;
      &:hover {
        color: #393E46;
      }
    }
    .placeholder {
        color: #C7C8CA;
    }
    a {
        color: #3271D9 !important;
        text-decoration: none !important;
    }
}
</style>
