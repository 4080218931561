<template>
    <v-dialog
        v-model="showDialog"
        scrollable
        max-width="750px">
        <v-card class="smfm-dialog-with-header">
            <v-toolbar flat class="smfm-page-subheading">
                {{ $t('pro.billing.billinginfo') }}
                <v-spacer></v-spacer>
                <v-btn
                    icon
                    @click.stop="closeDialog()">
                    <v-icon color="secondary">{{ iconClose }}</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card-text>
                <!-- Name -->
                <v-row>
                    <v-col cols="12" sm="12" class="pa-0">
                        <v-text-field outlined v-model="name" 
                            name="name" :label="$t('pro.billing.billingname')"></v-text-field>
                    </v-col>
                </v-row>
                <!-- Billing address 1 -->
                <v-row>
                    <v-col cols="12" sm="12" class="pa-0">
                        <v-text-field outlined v-model="address.line1" 
                            name="line1" :label="$t('pro.billing.addressline', {no: 1})"></v-text-field>
                    </v-col>
                </v-row>
                <!-- Billing address 2 -->
                <v-row>
                    <v-col cols="12" sm="12" class="pa-0">
                        <v-text-field outlined v-model="address.line2"
                            name="line2" :label="$t('pro.billing.addressline', {no: 2})"></v-text-field>
                    </v-col>
                </v-row>
                <!-- City & postal code -->
                <v-row>
                    <v-col cols="12" sm="6" :class="[$vuetify.breakpoint.xsOnly ? 'pa-0' : 'py-0 ps-0']">
                        <v-text-field outlined v-model="address.city" 
                            name="city" :label="$t('pro.billing.addresscity')"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" :class="[$vuetify.breakpoint.xsOnly ? 'pa-0' : 'py-0 pe-0']">
                        <v-text-field outlined v-model="address.postal_code" 
                            name="postal_code" :label="$t('pro.billing.addresspostalcode')"></v-text-field>
                    </v-col>
                </v-row>
                <!-- State & country -->
                <v-row>
                    <v-col cols="12" sm="6" :class="[$vuetify.breakpoint.xsOnly ? 'pa-0' : 'py-0 ps-0']">
                        <v-text-field outlined v-model="address.state" 
                            name="state" :label="$t('pro.billing.addressstate')"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" :class="[$vuetify.breakpoint.xsOnly ? 'pa-0' : 'py-0 pe-0']">
                        <v-select outlined v-model="address.country" 
                            name="country" :items="countries" :label="$t('pro.billing.addresscountry')"></v-select>
                    </v-col>
                </v-row>
                <!-- Email -->
                <v-row>
                    <v-col cols="12" sm="12" class="pa-0">
                        <v-text-field outlined v-model="email" dir="ltr"
                            name="email" :label="$t('pro.billing.billingemail')" disabled></v-text-field>
                    </v-col>
                </v-row>
                <div class="secondary--text">
                    {{ $t('pro.billing.billingdesc', {amount: `€ ${amount}`}) }}
                </div>
            </v-card-text>
            <v-card-actions class="d-flex justify-end align-center pt-5"
                :class="[$vuetify.breakpoint.smAndDown ? 'px-5 pb-7' : 'px-10 pb-10']">
                <v-btn text color="secondary" @click="closeDialog()">
                    {{ $t('app.cancel') }}
                </v-btn>
                <v-btn class="smfm-primary-btn smfm-lg-btn" @click="$emit('submit', { plan, name, address })">
                    {{$t('pro.billing.continuetopayment')}}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import { mdiClose } from "@mdi/js";
    import userMixin from "@/mixins/userMixin";

    export default {
        name: "ProBillingForm",
        mixins: [ userMixin ],
        props: {
            dialogVisible: {
                type: Boolean,
                default: false,
            },
            billinginfo: {
                type: Object,
                default: () => {}                
            },
            amount: { default: 0 },
            plan: null,
            value: null,
        },
        data() {
            return {
                address: {
                    line1: '',
                    line2: '',
                    city: '',
                    postal_code: '',
                    state: '',
                    country: ''
                },
                name: '',
                email: '',
                iconClose: mdiClose,
            }
        },
        computed: {
            showDialog: {
                get() {
                    return this.dialogVisible;
                },
                set(newValue) {
                    if(!newValue) this.$emit('close');
                }             
            },
            countries() {
                let obj = this.countriesNames[this.userLang]
                let res = []
                for (let k in obj) {
                    res.push({value: k, text: obj[k]})
                }
                return res
            }
        },
        methods: {
            closeDialog() {
                this.showDialog = false;
            },
            fillForm() {
                if (this.billinginfo) {
                    this.name = this.billinginfo.name ? this.billinginfo.name : '';
                    this.email = this.billinginfo.email ? this.billinginfo.email : '';
                    const { line1, line2, city, postal_code, state, country } = this.billinginfo.address;
                    this.address.line1 = line1 ? line1 : '';
                    this.address.line2 = line2 ? line2 : '';
                    this.address.city = city ? city : '';
                    this.address.postal_code = postal_code ? postal_code : '';
                    this.address.state = state ? state : '';
                    this.address.country = country ? country : '';
                }
            }
        },
        created() {
            this.fillForm()
        },
        watch: {
            billinginfo() {
                this.fillForm()
            }
        }
    }
</script>

<style scoped lang="scss">
</style>