<template>
    <div>
        <div class="d-flex justify-space-between align-center">
            <v-text-field class="mb-4 smfm-pro-edit-name" v-model="v"
                hide-details
                @keydown="save"
                @change="save">
            </v-text-field>
            <slot></slot>
        </div>
        <div class="secondary--text smfm-pro-hint">{{$t('pro.qrlist.nameedithint')}}</div>
    </div>
</template>

<script>
    import { mdiPencil } from '@mdi/js';
    import userMixin from "@/mixins/userMixin";

    export default {
        name: "ProEditableName",
        mixins: [userMixin],
        props: {
            value: null,
            codeid: null,
        },
        data() {
            return {
                lastsaved: null,
                v: null,
                max25chars: v => v.length <= 25 || this.$t('app.validation.maxlengthexceeded', {maxlength: 25}),
                iconEdit: mdiPencil,
            }
        },
        created() {
            this.lastsaved = this.value
            this.v = this.value
        },
        methods: {
            save() {
                if (this.lastsaved !== this.v) {
                    this.lastsaved = this.v
                    this.$emit('change', this.v)
                    this.authPatch({url: `pro/codes/${this.codeid}`, params: {name: this.v}})
                }
            },
        },
        watch: {
            value(v) {
                this.v = v
                this.lastsaved = v
            },
        },
    }
</script>