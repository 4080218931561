<template>
    <div>
        <v-overlay v-if="isloading"></v-overlay>

        <div class="d-flex flex-wrap justify-start mt-2">
            <!-- Delete all my data -->
            <simple-dialog :header="$t('pro.confirm')" :no-title="true"
                @save="deleteAllData">
                <template v-slot:activator="{open}">
                    <v-btn text :ripple="false"
                        class="delete-btn pa-0"
                        :class="{'light': light}"
                        @click.prevent="open">
                        {{ $t('pro.profile.deletemydata') }}
                    </v-btn>
                </template>
                {{ $t('pro.profile.confirmdeletedata') }}
                <template v-slot:buttons="{save}">
                    <v-btn class="smfm-lg-btn" color="error" @click="save">{{ $t('pro.profile.deletemydata') }}</v-btn>
                </template>
            </simple-dialog>

            <!-- Delete my account -->
            <simple-dialog v-if="canDeleteAccount"
                :header="$t('pro.confirm')" :no-title="true"
                @save="deleteAccount">
                <template v-slot:activator="{open}">
                    <v-btn text :ripple="false"
                        class="delete-btn pa-0 ms-7"
                        :class="{'light': light}"
                        @click.prevent="open">
                        {{ $t('pro.profile.deletemyaccount') }}
                    </v-btn>
                </template>
                <p v-html="$t('pro.profile.confirmdeleteaccount1')"></p>
                <p v-html="$t('pro.profile.confirmdeleteaccount2')"></p>
<!--                <p>Accounts of the organisation users will also be deactivated immediately.</p>-->
                <p v-if="userPlan.active && !userPlan.isTrial" v-html="$t('pro.profile.confirmdeleteaccount3')"></p>
                <p v-html="$t('pro.profile.confirmdeleteaccount4', {days: selfDeleteAccount})"></p>
                <template v-slot:buttons="{save}">
                    <v-btn class="smfm-lg-btn" color="error" @click="save">{{ $t('pro.profile.deletemyaccount') }}</v-btn>
                </template>
            </simple-dialog>
        </div>

        <simple-dialog persistent @save="goToApp" :buttontext="$t('pro.profile.returntodemo')" :show-cancel="false"
                       :show-close="false" title=" " ref="accountdeleted">
            {{ $t('pro.profile.accountclosed') }}
        </simple-dialog>
    </div>
</template>

<script>
    import { mapActions } from "vuex";
    import SimpleDialog from "@/components/forms/SimpleDialog";
    import userMixin from "@/mixins/userMixin";

    export default {
        name: "ProDeleteAccount",
        mixins: [ userMixin ],
        components: { SimpleDialog },
        props: {
            light: {
                type: Boolean,
                default: false
            },
        },
        data() {
            return {
                isloading: false
            }
        },
        computed: {
            selfDeleteAccount() {
                return process.env.VUE_APP_SELF_DELETE_ACCOUNT
            },
            canDeleteAccount() {
                return !(this.userPlan.isTrial && this.userPlan.ended)
            }
        },
        methods: {
            ...mapActions(['loadUserLibrary']),
            deleteAllData() {
                // TODO add confirmation where user has to type word "delete"
                // TODO add notification that data was deleted
                this.authPost({url: 'pro/user/delete-data'})
                    .then(() => this.loadUserLibrary())
            },
            deleteAccount() {
                this.isloading = true
                this.authPost({url: 'pro/user/delete'})
                    .then(() => this.$refs.accountdeleted.open())
            },
            goToApp() {
                window.location.href = process.env.VUE_APP_URL
            }
        }
    }
</script>

<style scoped lang="scss">
.delete-btn {
    height: auto !important;
    font-size: 14px !important;
    line-height: 21px;
    color: #C9454D;
    &.light {
        color: #fff;
        opacity: .5;
        text-decoration: underline;
    }
}
</style>