<template>
    <div>
        <v-overlay v-if="redirecting"></v-overlay>

        <!-- Plan active -->
        <div v-if="userPlan.active" class="plan-active">
            <!-- Plan renews -->
            <v-alert v-if="userPlan.renews && alertIsVisible" color="success" class="pa-5 mb-7">
                <div class="d-flex flex-wrap justify-space-between align-center">
                    <div class="d-flex align-center me-5" :class="{'mb-3': $vuetify.breakpoint.xsOnly}">
                        <div class="d-flex justify-center align-center info-icon-wrap me-4">
                            <v-icon color="white">{{ iconCheck }}</v-icon>
                        </div>
                        <div>
                            <div>
                                {{ $t('pro.billing.yourplan') }}
                                <span class="font-weight-bold">{{ $t('plan.'+userPlan.shortname) }}</span>
                            </div>
                            <div>
                                {{ $t('pro.billing.nextrenewal') }}
                                <span class="font-weight-bold">
                                    {{ formatDate(userPlan.renews * 1000) }}
                                </span>
                            </div>
                        </div>
                    </div>
                    <v-btn v-if="!userPlan.isTrial" 
                        class="manage-btn ms-auto"
                        @click.prevent="$refs.subscr.open()">
                        {{ $t('pro.billing.manage') }}
                    </v-btn>
                </div>
            </v-alert>

            <!-- Plan ends -->
            <v-alert v-if="userPlan.ends && alertIsVisible" color="info" class="pa-5 mb-7">
                <div class="d-flex flex-wrap justify-space-between align-center">
                    <div class="d-flex align-center me-7" :class="{'mb-3': $vuetify.breakpoint.xsOnly}">
                        <div class="d-flex justify-center align-center info-icon-wrap me-4">
                            <v-icon color="white">{{ iconInfo }}</v-icon>
                        </div>
                        <div>
                            <div>
                                <span v-if="userPlan.isTrial">
                                    {{ $t('pro.billing.yourplan') }}
                                    <span class="font-weight-bold">{{ $t('plan.'+userPlan.shortname) }}</span>
                                </span>
                                <span v-else>
                                    {{ $t('pro.billing.subscriptionwithoutrenewal') }}
                                </span>
                            </div>
                            <div>
                                {{ $t('pro.billing.planends') }}
                                <span class="font-weight-bold">
                                    {{formatDate(userPlan.ends * 1000)}} ({{timeLeft}})
                                </span>
                            </div>
                        </div>
                    </div>
                    <div v-if="showChoosePlan" class="ms-auto" :class="{'me-7': showClose && $vuetify.breakpoint.smAndUp}">
                        <div v-if="dashboard">
                            <v-btn v-if="userPlan.isTrial"
                                :to="{ name: 'subscription' }"
                                class="manage-btn">
                                {{ $t('pro.billing.chooseplan') }}
                            </v-btn>
                        </div>
                        <div v-else>
                            <v-btn v-if="!userPlan.isTrial" class="manage-btn"
                                @click.prevent="$refs.subscr.open()">
                                {{ $t('pro.billing.renew') }}
                            </v-btn>
                        </div>
                    </div>
                </div>
                <v-icon v-if="showClose" class="hide-alert" @click="alertIsVisible = false">{{ iconClose }}</v-icon>
            </v-alert>
        </div>

        <!-- Plan ended -->
        <div v-else-if="userPlan.ended" class="smfm-page-heading mb-5">
            <span v-if="userPlan.isTrial">
                {{ $t('pro.billing.trialended') }}
            </span>
            <span v-else>
                {{ $t('pro.billing.nosubscription') }}
            </span>
        </div>

        <!-- Pricing plans -->
        <div v-if="isPricingPlansVisible">
            <div class="prices-list"
                :class="{'with-margin': currentCurrCode !== 'EUR'}">
                <about-pricing-dropdown></about-pricing-dropdown>
            </div>
            <about-pricing-plans
                :plans="plans" 
                :features="features"
                @connectPlan="connectPlan">
            </about-pricing-plans>
        </div>

        <!-- Plan ended -->
        <div v-if="userPlan.ended" class="plan-active mt-5">
            <!-- Plan ended -->
            <v-alert color="error" class="pa-5 mb-7">
                <div class="d-flex flex-wrap justify-space-between align-center">
                    <div class="d-flex align-center me-5" :class="{'mb-3': $vuetify.breakpoint.xsOnly}">
                        <div class="d-flex justify-center align-center info-icon-wrap me-4">
                            <v-icon color="white">{{ iconInfo }}</v-icon>
                        </div>
                        <div v-html="$t('pro.billing.yourplanended', {date: willBeDeletedAt})" class="descr">
                        </div>
                    </div>
                    <pro-delete-account :light="true" class="ms-auto">
                    </pro-delete-account>
                </div>
            </v-alert>
        </div>

        <!-- Billing form dialog -->
        <pro-billing-form
            v-if="billinginfo && billinginfo.email"
            :dialogVisible="billingFormVisible"
            :plan="selectedPlan && selectedPlan.shortname"
            :amount="selectedPlan && selectedPlan.price"
            :billinginfo="billinginfo"
            @submit="signUpSubmit"
            @close="billingFormVisible=false">
        </pro-billing-form>
        <!-- Continue to Stripe dialog -->
        <simple-dialog width="500" :header="$t('pro.billing.managesubscription')" :no-title="true"
            @save="openCustomerPortal" ref="subscr">
            <div v-html="userPlan.ends && !userPlan.isTrial? $t('pro.billing.stripedescrenewal') : $t('pro.billing.stripedesc')"></div>
            <template v-slot:buttons="{save}">
                <v-btn class="smfm-primary-btn smfm-lg-btn" @click="save">{{ $t('pro.billing.continuetostripe') }}</v-btn>
            </template>
        </simple-dialog>
    </div>
</template>

<script>
    import { mapGetters } from "vuex";
    import { mdiInformationOutline, mdiCheckboxMarkedCircleOutline, mdiClose } from '@mdi/js';
    import userMixin from "@/mixins/userMixin";
    import moment from 'moment';
    import ProBillingForm from "@/components/pro/settings/ProBillingForm";
    import SimpleDialog from "@/components/forms/SimpleDialog";
    import AboutPricingDropdown from "@/components/about/AboutPricingDropdown";
    import AboutPricingPlans from "@/components/about/AboutPricingPlans";
    import ProDeleteAccount from "@/components/pro/settings/ProDeleteAccount"
    const UNLIMITED = 'unlimited';

    /**
     All possible combinations of subscriptions properties:
     1. User has logged in for the first time
        userPlan.active == false, userPlan.canSignUp == true, userPlan.canStartTrial == true
     2. User is in the trial period
        userPlan.active == true, userPlan.canSignUp == true, userPlan.canStartTrial == false, userPlan.isTrial == true
     3. User trial period has ended
        userPlan.active == false, userPlan.ended == true, userPlan.canSignUp == true, userPlan.canStartTrial == false, userPlan.isTrial == true
     4. User has an active subscription:
        userPlan.active == true, userPlan.canSignUp == false, userPlan.canStartTrial == false, userPlan.isTrial == false
        4.1 User is signed up for renewals
            userPlan.renews (in the future), userPlan.ends == 0
        4.2 User cancelled the subscription, it will be active until the end of the period and will not be renewed
            userPlan.renews == 0, userPlan.ends (in the future)
     5. User had a paid subscription in the past but it is no longer active (was cancelled or card expired/was declined)
        userPlan.active == false, userPlan.ended == true, userPlan.canSignUp == true, userPlan.canStartTrial == false, userPlan.isTrial == false
        (both buttons to "Renew" and "Sign up" should be displayed)
     */

    export default {
        name: "ProSubscriptionInfo",
        mixins: [ userMixin ],
        components: { SimpleDialog, ProBillingForm, AboutPricingPlans, ProDeleteAccount, AboutPricingDropdown },
        props: {
            showChoosePlan: {
                type: Boolean,
                default: false
            },
            showClose: {
                type: Boolean,
                default: false
            },
            dashboard: {
                type: Boolean,
                default: false
            },
        },
        data() {
            return {
                billinginfo: null,
                currentTime: null,
                redirecting: false,
                alertIsVisible: true,
                features: [
                    {code: 'demo', label: this.$t('app.pricing.featuredemo')},
                    {code: 'systemlogos', label: this.$t('app.pricing.featurelogos')},
                    {code: 'systemtemplates', label: this.$t('app.pricing.featuretemplates')},
                    {code: 'library', label: this.$t('app.pricing.featurelibrary')},
                    //{code: 'savestatic', label: ' saved static codes'},
                    {code: 'dynamic', label: this.$t('app.pricing.dynamiccodes')},
                    {code: 'uploads', label: this.$t('app.pricing.uploadedfiles')},
                    {code: 'apis', label: this.$t('app.pricing.apicalls'), showunlimited: true},
                    //{code: 'usebulk', label: 'Coming soon: Generate QR codes <strong>in bulk</strong>'},
                    {code: 'users', label: this.$t('app.pricing.additionalusers'), label1: this.$t('app.pricing.additionaluser')},
                ], 
                billingFormVisible: false,  
                selectedPlan: null,           
            }
        },
        computed: {
            ...mapGetters("currency", ["currencies", "currentCurrCode", "currentCurrency"]),
            timeLeft() {
                let delta = this.userPlan.ends - this.currentTime
                return delta > 0 ? moment.duration(delta, 's').humanize() : 'ended'
            },
            iconCheck() { return mdiCheckboxMarkedCircleOutline },
            iconInfo() { return mdiInformationOutline },
            iconClose() { return mdiClose },
            plans() {
                return [
                    ...this.pricePlans.map(plan => {
                        return {name: this.$t('plan.'+plan.shortname), price: plan.price, featured: plan.featured, shortname: plan.shortname,
                                features: {demo: false, systemlogos: false, systemtemplates: false, library: UNLIMITED, ...plan.features}}
                    })]
            },
            isPricingPlansVisible() {
                return (this.dashboard && this.userPlan.ended) || (!this.dashboard && this.userPlan.isTrial)
            },
            willBeDeletedAt() {
                let timeout = process.env.VUE_APP_AUTO_DELETE_ACCOUNT
                if (this.userPlan.isTrial) {
                    timeout = process.env.VUE_APP_AUTO_DELETE_ACCOUNT_TRIAL
                }
                const expires = parseInt(this.currentUser['custom:planends']) + parseInt(timeout) * 24 * 3600
                return this.formatDate(expires * 1000)
            },
        },
        created() {
            this.loadStripeLibrary()
            let searchParams = new URLSearchParams(window.location.search)
            if (this.$route.name === 'payment_success' || searchParams.get('billing_updated')) {
                // TODO we really only need reconcile for the dev, otherwise it is updated in webhooks
                this.authPost({url: 'billing/reconcile'})
                    .then(resp => resp.data ? this.refreshUser() : null)
                    .then(() => this.$router.replace(this.userPlan.active ? { name: 'subscription' } : '/'))

            } else if (this.$route.name === 'payment_cancelled') {
                //console.log('----Stripe payment cancelled')
                this.$router.replace(this.userPlan.active ? { name: 'subscription' } : '/')
            } else {
                this.currentTime = Math.round((new Date().getTime())/1000)
                if (this.isPricingPlansVisible) {
                    this.retrieveBillingInfo()
                }
            }
        },
        methods: {
            retrieveBillingInfo() {
                if (this.billinginfo === null) {
                    this.billinginfo= {}
                    this.authGet({url: 'billing'})
                        .then(resp => this.billinginfo = resp.data)
                }
            },
            connectPlan(data) {
                this.selectedPlan = data;
                this.billingFormVisible = true;
            },
            signUpSubmit({name, plan, address}) {
                this.redirecting = true
                this.loadStripeLibrary()
                    .then(() => this.authPost({url: 'billing/create-checkout-session',
                                               params: { plan, returnurl: this.proUrl, name, address }}))
                    .then(function(resp) {
                        // Call Stripe.js method to redirect to the new Checkout page
                        // eslint-disable-next-line no-undef
                        const stripe = Stripe(process.env.VUE_APP_STRIPE_PK);
                        stripe.redirectToCheckout({sessionId: resp.data.sessionId})
                    })
                    .catch(err => {
                        console.error(err)
                        this.redirecting = false
                    })
            },
            openCustomerPortal() {
                this.redirecting = true
                this.authPost({url: 'billing/create-customer-portal-session',
                               params: { returnurl: this.proUrl + '/settings/subscription?billing_updated=1' }})
                    .then(resp => {
                        window.location.href = resp.data.url
                    })
                    .catch(err => {
                        console.error(err)
                        this.redirecting = false
                    })
            }
        }
    }
</script>

<style scoped lang="scss">
.plan-active {
    .info-icon-wrap {
        width: 50px;
        height: 50px;
        min-width: 50px;
        border: 1px solid #FFFFFF;
        border-radius: 50%;
    }
    .v-alert {
        color: #fff;
    }
    .manage-btn {
      background: #393E46 !important;
      color: #fff;
    }
    .hide-alert {
        position: absolute;
        top: 35px;
        right: 20px;
    }
    .descr {
        max-width: 460px;
    }
}
.v-application--is-rtl {
    .plan-active .hide-alert {
        left: 20px;
        right: auto;
    }
}
.prices-list {
    position: relative;
    height: 50px;
    &.with-margin {
        margin-bottom: 74px;
        @media(max-width: 960px) {
            margin-bottom: 84px;
        }
    }
}
</style>