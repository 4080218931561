import { render, staticRenderFns } from "./DynamicFormPage.vue?vue&type=template&id=5a4086da&scoped=true&"
import script from "./DynamicFormPage.vue?vue&type=script&lang=js&"
export * from "./DynamicFormPage.vue?vue&type=script&lang=js&"
import style0 from "./DynamicFormPage.vue?vue&type=style&index=0&id=5a4086da&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5a4086da",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VIcon,VTextField})
