<template>
  <v-card flat class="smfm-pro-settings-card subscription" :class="[$vuetify.breakpoint.smAndDown ? 'pa-5' : 'pa-7']">
      <div class="smfm-page-subheading mb-6">{{ $t('pro.profile.subscription') }}</div>
      <pro-subscription-info :show-choose-plan="true"></pro-subscription-info>

      <div v-if="!userPlan.isTrial">
        <div class="mb-2 secondary--text">{{ $t('pro.profile.planusage') }}:</div>
        <loading v-if="isloading" :size="2" class="my-10"></loading>
        <v-row v-else>
            <v-col cols="12" sm="6" md="6" lg="6" class="pl-0"
                v-for="stats in planUsage" :key="stats.title">
                <div class="pa-7 usage" :class="{'disabled': isBasic && stats.type === 'users'}">
                    <div class="smfm-pro-heading mb-7">{{ stats.title }}</div>
                    <div class="d-flex justify-space-between align-center mb-3">
                        <div class="smfm-pro-heading">
                            <span v-if="isBasic && stats.type === 'users'">
                                {{ $t('pro.profile.notavailable') }}
                            </span>
                            <span v-else>
                                {{ $t('pro.profile.usageof', {
                                    used: (stats.type === 'uploads' ? getUsedUploadsInMB : stats.used),
                                    total: (stats.type === 'uploads' ? userPlan.features.uploads : stats.total)
                                }) }}
                            </span>
                        </div>
                        <div v-if="!(isBasic && stats.type === 'users')" class="secondary--text">
                            ({{ $t('pro.profile.usageleft', {n: calculatePercentLeft(stats)}) }})
                        </div>
                    </div>
                    <v-progress-linear
                        :value="calculatePercentUsage(stats)"
                        height="10"
                        striped
                        rounded
                        :disabled="isBasic"
                        :color="isBasic && stats.type === 'users' ? 'primary' : (calculatePercentUsage(stats) <= 75 ? 'primary' : 'error')">
                    </v-progress-linear>
                </div>
            </v-col>
        </v-row>
      </div>
  </v-card>
</template>

<script>
    import userMixin from "@/mixins/userMixin";
    import ProSubscriptionInfo from "@/components/pro/settings/ProSubscriptionInfo";
    import Loading from "@/components/common/Loading";

    export default {
        name: "ProSubscription",
        components: { ProSubscriptionInfo, Loading },
        mixins: [ userMixin ],
        data() {
            return {
                usage: {
                    dynamic: 0,
                    uploads: 0,
                    apis: 0,
                    users: 0,
                },
                isloading: false,
            }
        },
        computed: {
            getTotalUploadsInBytes() {
                const total = Number(this.userPlan.features.uploads.slice(0, -2));
                return total * 1024 * 1024;
            },
            getUsedUploadsInMB() {
                return `${(this.usage.uploads / 1024 / 1024).toFixed(1)}MB`;
            },
            isBasic() {
                return this.userPlan.shortname === 'basic';
            },
            planUsage() {
                return [
                    { 
                        type: 'dynamic', 
                        title: this.$t('pro.profile.dynamiccodes'),
                        total: this.userPlan.features.dynamic, 
                        used: this.usage.dynamic, 
                    },
                    { 
                        type: 'uploads', 
                        title: this.$t('pro.profile.uploadedfiles'),
                        total: this.getTotalUploadsInBytes, 
                        used: this.usage.uploads, 
                    },
                    { 
                        type: 'apis', 
                        title: this.$t('pro.profile.apicalls'),
                        total: this.userPlan.features.apis, 
                        used: this.usage.apis, 
                    },
                    { 
                        type: 'users', 
                        title: this.$t('pro.profile.additionalusers'),
                        total: this.userPlan.features.users, 
                        used: this.usage.users, 
                    },
                ]
            }
        },
        methods: {
            calculatePercentUsage(stats) {
                return (stats.used * 100)/stats.total;
            },
            calculatePercentLeft(stats) {
                const left = 100 - this.calculatePercentUsage(stats);
                if (Number.isInteger(left)) {
                    return left;
                } else {
                    return left.toFixed(1);
                }
            },
            loadUsage() {
                this.isloading = true;
                this.authGet({url: 'pro/user/usage'})
                    .then((resp) => {
                        this.usage = resp.data;
                    })
                    .finally(() => this.isloading = false)
            }
        },
        created() {
            this.loadUsage();
        }
    }
</script>

<style scoped lang="scss">
.subscription {
    @media(min-width: 1264px) {
        width: calc(100% - 371px);
    }
}
.usage {
  border: 1px solid #C7C8CA;
  border-radius: 20px;
  &.disabled {
    border-color: #EEEEEE;
    .smfm-pro-heading {
        color: #909295;
        pointer-events: none;
    }
  }
}
</style>