import Vue from 'vue'
import Pro from './views/Pro'
import pro from './router/pro'
import store from './store/index-pro'
import vuetify from './plugins/vuetify'
import {i18n} from './i18n'
import globalMixin from "@/mixins/globalMixin";
import Router from 'vue-router';
import "@/assets/shared/scss/smfm.scss";
import "@/assets/shared/scss/vuetify.scss";
import "@/assets/pro/scss/global.scss";

// https://www.npmjs.com/package/vcards-js
window.Vcard = require('vcards-js')

// VueFileAgent for Pro: https://safrazik.com/vue-file-agent/
import VueFileAgent from 'vue-file-agent'
import 'vue-file-agent/dist/vue-file-agent.css'
Vue.use(VueFileAgent)

// https://github.com/Jexordexan/vue-slicksort (required by vuefileagent)
import { SlickList, SlickItem } from 'vue-slicksort'
Vue.component('vfa-sortable-list', SlickList)
Vue.component('vfa-sortable-item', SlickItem)

// Disable annoying message "You are running Vue in development mode"
Vue.config.productionTip = false

// Preload all dynamic and static components
let files = require.context('@/components/dynamic', true, /\.vue$/i)
files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

files = require.context('@/components/static', true, /\.vue$/i)
files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

const originalPush = Router.prototype.push;
Router.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}

// Special components that are used in QrStyles, this is shared between demo and pro but
// the advanced components should be loaded only in pro:
import ProSavePreset from './components/pro/ProSavePreset'
Vue.component('pro-save-preset', ProSavePreset)

import InputFile from './components/forms/InputFile'
Vue.component('input-file', InputFile)

Vue.mixin(globalMixin)
new Vue({
    router: pro,
    store,
    vuetify,
    i18n,
    render: h => h(Pro)
}).$mount('#app')
