export default {
  namespaced: true,

  state: () => ({
    statsParams: {
      total: { timelimit: 'all' },
      byhour: { tz: 'user', timelimit: 'all' },
      bycountry: { timelimit: 'all' },
      bycity: { timelimit: 'all' },
      bymonth: { timelimit: 'all' },
      byos: { timelimit: 'all' },
      bylang: { timelimit: 'all' },
      mostscanned: { limit: 10, timelimit: 'all' },
      scans: { limit: 100 },
    },
    hasDynamicCodes: false,
  }),

  getters: {
    statsParams(state) { return state.statsParams },
    getSelectedParam: (state) => (data) => {
      return data.type === 'timelimit' ? state.statsParams[data.field].timelimit : state.statsParams[data.field].tz
    },
    hasDynamicCodes(state) { return state.hasDynamicCodes },
  },

  mutations: {
    _setStatsParam(state, payload) {
      if (payload.type === 'timelimit') {
        state.statsParams[payload.field].timelimit = payload.value
      } else {
        state.statsParams[payload.field].tz = payload.value
      }
    },
    _setHasDynamicCodes(state, payload) {
      state.hasDynamicCodes = payload;
    }
  },

  actions: {
    setStatsParam({commit}, data) {
      commit('_setStatsParam', data)
    },
    setHasDynamicCodes({commit}, data) {
      commit('_setHasDynamicCodes', data)
    } 
  }
}