<template>
  <v-card flat class="smfm-pro-settings-card smfm-w-100" :class="[$vuetify.breakpoint.smAndDown ? 'pa-5' : 'pa-7']">
      <div class="smfm-page-subheading mb-6">{{ $t('pro.profile.password') }}</div>
      <v-form ref="form" v-model="formValid">
        <v-text-field outlined v-model="passwordForm.old"
          :type="showOld ? 'text' : 'password'" dir="ltr"
          :rules="[requiredRule, noSpacesRule, minlengthRule(6)]"
          :label="$t('pro.profile.currentpassword')"
          :append-icon="showOld ? iconShow : iconHide"
          @click:append="showOld = !showOld">
        </v-text-field>
        <v-text-field outlined v-model="passwordForm.new"
          :type="showNew ? 'text' : 'password'" dir="ltr"
          :rules="[requiredRule, noSpacesRule, minlengthRule(6)]"
          :label="$t('pro.profile.newpassword')"
          :append-icon="showNew ? iconShow : iconHide"
          @click:append="showNew = !showNew">
        </v-text-field>
        <v-text-field outlined v-model="passwordForm.confirm"
          :type="showConfirm ? 'text' : 'password'" dir="ltr"
          :rules="[
            requiredRule, noSpacesRule, minlengthRule(6),
            passwordForm.new === passwordForm.confirm || $t('app.validation.passwordsmatch')
          ]"
          :label="$t('pro.profile.confirmpassword')"
          :append-icon="showConfirm ? iconShow : iconHide"
          @click:append="showConfirm = !showConfirm">
        </v-text-field>
        <v-btn class="smfm-primary-btn smfm-md-btn"
          :block="$vuetify.breakpoint.xsOnly"
          :disabled="!(formValid)"
          @click="updatePassword">
          <loading v-if="isloading"></loading>
          <span v-else>{{ $t('pro.save') }}</span>
        </v-btn>
      </v-form>
        <!-- Notifications dialog -->
        <notifications-dialog
            :dialogVisible="notificationsDialogVisible"
            @close="notificationsDialogVisible=false">
        </notifications-dialog>
  </v-card>
</template>

<script>
    import { mdiEye, mdiEyeOff } from '@mdi/js';
    import validation from "@/mixins/validation";
    import userMixin from "@/mixins/userMixin";
    import EventBus from '@/plugins/EventBus.js';
    import Loading from "@/components/common/Loading";
    import NotificationsDialog from "@/components/about/NotificationsDialog";

    export default {
        name: "ProPassword",
        mixins: [ validation, userMixin ],
        components: { Loading, NotificationsDialog },
        data() {
            return {
                formValid: true,
                passwordForm: {
                    old: '',
                    new: '',
                    confirm: '',
                },
                showOld: false,
                showNew: false,
                showConfirm: false,
                isloading: false,
                notificationsDialogVisible: false,
            }
        },
        computed: {
            iconShow() { return mdiEye },
            iconHide() { return mdiEyeOff },
        },
        methods: {
            updatePassword() {
                const valid = this.$refs.form.validate();
                if (valid) {
                    this.isloading = true;
                    this.changePassword(this.passwordForm.old, this.passwordForm.new)
                        .then(() => {
                            EventBus.$emit('showNotification', {
                                type: 'success',
                                title: this.$t('app.contact.thankyou'),
                                subtitle: this.$t('pro.profile.passwordsuccess'),
                                buttontext: this.$t('app.contact.gotit')
                            });
                            this.notificationsDialogVisible = true;
                            this.$refs.form.reset();
                        })
                        .catch(() => {
                            EventBus.$emit('showNotification', {
                                type: 'error',
                                title: this.$t('app.contact.sorry'),
                                subtitle: this.$t('app.contact.smthwrong'),
                                buttontext: this.$t('app.contact.illtry')
                            });
                            this.notificationsDialogVisible = true;
                        })
                        .finally(() => this.isloading = false)
                } else {
                    return;
                }
            }
        },
    }
</script>