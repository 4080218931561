<template>
  <div class="d-flex flex-column flex-lg-row smfm-h-100 smfm-pro-settings">
    <v-card flat class="settings-menu" 
      :class="[
        $vuetify.breakpoint.mdAndDown ? 'mb-4' : 'me-4 smfm-h-100',
        $vuetify.breakpoint.smAndDown ? 'pa-5' : 'pa-7'
      ]">
      <div class="smfm-page-heading mb-3">{{ currentUser.name }}</div>
      <v-list>
        <template v-for="item in settingsmenu">
          <v-list-item :key="item.name" link exact
            v-if="!item.disabled"
            :ripple="false"
            :to="{ name: item.name }">
            <v-list-item-icon class="my-5">
              <v-icon color="#03949A">{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="text-body-1 font-weight-medium">
                {{ item.text }}
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-icon v-if="$vuetify.breakpoint.lgAndUp" class="my-5 append">
              <v-icon color="primary">{{ iconRight }}</v-icon>
            </v-list-item-icon>
          </v-list-item>
        </template>
      </v-list>
    </v-card>
    <router-view :key="$route.fullPath"></router-view>
  </div>
</template>

<script>
    import { mdiChevronRight, mdiChevronLeft } from '@mdi/js';
    import userMixin from "@/mixins/userMixin";
    import customIcons from "@/mixins/customIcons";

    export default {
        name: "ProSettings",
        mixins: [ userMixin, customIcons ],
        data() {
            return {
               
            }
        },
        computed: {
            settingsmenu() {
                return [
                    { text: this.$t('pro.profile.preferences'), name: 'preferences', icon: this.iconPreferences, disabled: false },
                    { text: this.$t('pro.profile.myaccount'), name: 'account', icon: this.iconAccount, disabled: false },
                    { text: this.$t('pro.profile.password'), name: 'password', icon: this.iconPassword, disabled: !this.canChangePassword() },
                    { text: this.$t('pro.profile.subscription'), name: 'subscription', icon: this.iconSubscription, disabled: false },
                ]
            },
            iconRight() { return this.isRTL ? mdiChevronLeft : mdiChevronRight }
        }
    }
</script>

<style scoped lang="scss">
.settings-menu {
  min-width: 355px;
  .v-list-item {
    border-radius: 12px;
    &:before {
      border-radius: 12px;
    }
    .v-list-item__content {
      padding: 20px 0;
      .v-list-item__title {
        color: #393E46;
        line-height: 24px;
        z-index: 9;
      }
    }
    .v-list-item__icon.append {
      display: none;
    }
    &.v-list-item--active {
      .v-list-item__icon.append {
        display: flex;
      }
      &:before {
        background-color: #CCEFF0 !important;
        opacity: 1;
      }
    }
  }
}
</style>