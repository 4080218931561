<template>
    <pro-qr-list type="static" @edit="editQrStyle"></pro-qr-list>
</template>

<script>
    import ProQrList from "@/components/pro/lists/ProQrList";

    export default {
        name: "ProListStatic",
        components: { ProQrList },
        methods: {
            editQrStyle(codeid) {
                this.$router.push(`/static/${codeid}`);
            },
        }
    }
</script>
