<template>
  <div>
    <div class="currency">
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn outlined v-bind="attrs" v-on="on" class="currency-btn">
            {{ currentCurrCode }}
            <v-icon>
              {{ attrs["aria-expanded"] === "true" ? iconChevronUp : iconChevronDown }}
            </v-icon>
          </v-btn>
        </template>
        
        <v-list dense width="180">
          <v-list-item v-for="curr in currencies" :key="curr.code"
            :class="{'selected': curr.code === currentCurrCode}"
            @click="changeCurrency(curr)">
            <v-list-item-title class="text-body-1 font-weight-medium">{{ curr.label }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
    <div v-if="currentCurrCode !== 'EUR'" class="currency-text secondary--text">
        {{$t('app.pricing.exrateexplanation', {curr: currentCurrCode})}}
    </div>
  </div>
</template>

<script>
    import { mapActions, mapGetters } from "vuex";
    import { mdiChevronDown, mdiChevronUp } from '@mdi/js';

    export default {
        name: "AboutPricingDropdown",
        computed: {
            ...mapGetters("currency", ["currencies", "currentCurrCode", "currentCurrency"]),
            iconChevronDown() { return mdiChevronDown },
            iconChevronUp() { return mdiChevronUp },
        },
        methods: {
            ...mapActions("currency", ["setCurrentCurr", "getCurrencies"]),
            changeCurrency(curr) {
                this.setCurrentCurr(curr.code);
            },
        },
        created() {
            this.getCurrencies();
        },
    }
</script>

<style scoped lang="scss">
.currency {
  position: absolute;
  top: 0;
  left: 0;
  .currency-btn {
    border: 1px solid #C7C8CA;
    border-radius: 10px;
    padding: 0 7px;
    color: #393E46;
  }
  @media(max-width: 960px) {
    right: 0;
    left: auto;
  }
}
.currency-text {
  position: absolute;
  bottom: -70px;
  left: 0;
  max-width: 350px;
  font-size: 14px;
  font-weight: normal;
  line-height: 21px;
  text-align: start;
  @media(max-width: 960px) {
    bottom: -80px;
    right: 0;
    left: auto;
    text-align: right;
  }
}
</style>

