<template>
    <div class="dashboard">
        <v-row>
            <v-col md="12" :lg="userPlan.ended ? 9: 8" cols="12" class="smfm-pro-cards ps-0 pb-4">
                <v-card flat :tile="$vuetify.breakpoint.smAndDown"
                    :class="[$vuetify.breakpoint.smAndDown ? 'pa-5' : 'pa-7']">
                    <!-- Can start trial -->
                    <pro-starting-trial></pro-starting-trial>

                    <!-- Subscription info -->
                    <pro-subscription-info
                        v-if="userPlan.isTrial || userPlan.ended || !userPlan.renews"
                        :dashboard="true"
                        :show-choose-plan="true" :show-close="true">
                    </pro-subscription-info>

                    <div v-if="userPlan.active">
                        <!-- Main stats -->
                        <div class="main-stats-grid mb-5">
                            <router-link v-for="stats in mainStats"
                                :key="stats.type" :to="{ name: stats.name }"
                                class="py-5 main-stat">
                                <div class="d-flex flex-column justify-center align-center">
                                    <img :src="`/images/pro/${stats.type}.svg`"
                                        width="41" height="40"
                                        :alt="stats.name" class="mb-3"/>
                                    <span class="text-center">{{ stats.text }}</span>
                                    <span class="count">{{ stats.value }}</span>
                                </div>
                            </router-link>
                        </div>

                        <!-- Total scans -->
                        <v-card outlined class="smfm-dashboard-card">
                            <pro-dashboard-toolbar :title="$t('pro.dashboard.totalscans')"
                                :has-data="hasDynamicCodes"
                                :withList="false" :withBtns="true"
                                :field="'total'"
                                @change="update($event, 'total', 0)"
                                @refresh="onRefresh('total', 0)">
                            </pro-dashboard-toolbar>                            
                            <div class="pa-5">
                                <loading v-if="loading[0]" :size="1" class="loading xl"></loading>
                                <div v-else class="total-scans">
                                    <div v-if="hasDynamicCodes && metrics.total && metrics.total[0].scanstimeline && metrics.total[0].scanstimeline.length > 0">
                                        <div class="title mb-3">
                                            <span class="text--primary">{{metrics.total[0].cnt}}</span>
                                            <span class="secondary--text">({{metrics.total[0].cntunique}} {{$t('pro.dashboard.uniquecnt')}})</span>
                                        </div>
                                        <div class="d-flex flex-wrap justify-space-between align-center mb-4">
                                            <div class="text--primary text-body-1 font-weight-medium">
                                                {{ $t('pro.dashboard.scanstimeline') }}
                                            </div>
                                            <pro-chart-legend></pro-chart-legend>
                                        </div>
                                        <stacked-bar-chart :chart-data="scanstimelineData" :height="270"></stacked-bar-chart>
                                    </div>
                                    <pro-scans-no-data v-else 
                                        :icon-type="'total'"
                                        :size="'xl'"
                                        :has-data="hasDynamicCodes"
                                        :has-create-btn="true">
                                    </pro-scans-no-data>
                                </div>
                            </div>
                        </v-card>

                        <v-row>
                            <!-- Scans by months -->
                            <v-col cols="12" sm="12" md="12" lg="6" :class="[$vuetify.breakpoint.mdAndDown ? 'px-0' : 'ps-0']">
                                <v-card outlined class="smfm-dashboard-card">
                                    <pro-dashboard-toolbar :title="$t('pro.dashboard.scansbymonths')"
                                        :has-data="hasDynamicCodes"
                                        :by-month="true"
                                        :field="'bymonth'"
                                        @change="update($event, 'bymonth', 1)"
                                        @refresh="onRefresh('bymonth', 1)">
                                    </pro-dashboard-toolbar>
                                    <div class="pa-5">
                                        <loading v-if="loading[1]" :size="1" class="loading lg"></loading>
                                        <div v-else>
                                            <div v-if="hasDynamicCodes && metrics.bymonth && metrics.bymonth.length > 0">
                                                <div class="d-flex justify-end align-center mb-4">
                                                    <pro-chart-legend></pro-chart-legend>
                                                </div>
                                                <stacked-bar-chart :chart-data="monthsData" :height="270"></stacked-bar-chart>
                                            </div>
                                            <pro-scans-no-data v-else 
                                                :icon-type="'date'"
                                                :size="'lg'"
                                                :has-data="hasDynamicCodes">
                                            </pro-scans-no-data>
                                        </div>
                                    </div>
                                </v-card>
                            </v-col>
                            <!-- Scans by hour of the day -->
                            <v-col cols="12" sm="12" md="12" lg="6" :class="[$vuetify.breakpoint.mdAndDown ? 'px-0' : 'pe-0']">
                                <v-card outlined class="smfm-dashboard-card">
                                    <pro-dashboard-toolbar :title="$t('pro.dashboard.scansbyhour')"
                                        :has-data="hasDynamicCodes"
                                        :field="'byhour'"
                                        @change="update($event, 'byhour', 2)"
                                        @refresh="onRefresh('byhour', 2)">
                                    </pro-dashboard-toolbar>
                                    <div class="pa-5">
                                        <loading v-if="loading[2]" :size="1" class="loading lg"></loading>
                                        <div v-else>
                                            <div v-if="hasDynamicCodes && metrics.byhour && metrics.byhour.length > 0">
                                                <div class="d-flex flex-wrap justify-space-between align-center mb-4">
                                                    <pro-timezone :timezone="timezone"
                                                        @change="changeTimezone($event, 'byhour', 2)">
                                                    </pro-timezone>
                                                    <pro-chart-legend></pro-chart-legend>
                                                </div>                                                
                                                <stacked-bar-chart :chart-data="hoursData" :height="270"></stacked-bar-chart>
                                            </div>
                                            <pro-scans-no-data v-else 
                                                :icon-type="'date'"
                                                :size="'lg'"
                                                :has-data="hasDynamicCodes">
                                            </pro-scans-no-data>
                                        </div>
                                    </div>
                                </v-card>                            
                            </v-col>
                        </v-row>

                        <v-row>
                            <!-- Scans by OS -->
                            <v-col cols="12" sm="12" md="12" lg="6" :class="[$vuetify.breakpoint.mdAndDown ? 'px-0' : 'ps-0']">
                                <v-card outlined class="smfm-dashboard-card">
                                    <pro-dashboard-toolbar :title="$t('pro.dashboard.scansbyos')"
                                        :has-data="hasDynamicCodes"
                                        :field="'byos'"
                                        @change="update($event, 'byos', 3)"
                                        @refresh="onRefresh('byos', 3)">
                                    </pro-dashboard-toolbar>
                                    <div class="pa-5">
                                        <loading v-if="loading[3]" :size="1" class="loading"></loading>
                                        <div v-else>
                                            <div v-if="hasDynamicCodes && metrics.byos && metrics.byos.length > 0">
                                                <doughnut-chart :chart-data="osData" :height="200"></doughnut-chart>
                                            </div>
                                            <pro-scans-no-data v-else 
                                                :icon-type="'os'"
                                                :has-data="hasDynamicCodes">
                                            </pro-scans-no-data>
                                        </div>
                                    </div>
                                </v-card>
                            </v-col>
                            <!-- Scans by language -->
                            <v-col cols="12" sm="12" md="12" lg="6" :class="[$vuetify.breakpoint.mdAndDown ? 'px-0' : 'pe-0']">
                                <v-card outlined class="smfm-dashboard-card">
                                    <pro-dashboard-toolbar :title="$t('pro.dashboard.scansbylang')"
                                        :has-data="hasDynamicCodes"
                                        :field="'bylang'"
                                        @change="update($event, 'bylang', 4)"
                                        @refresh="onRefresh('bylang', 4)">
                                    </pro-dashboard-toolbar>                                    
                                    <div class="pa-5">
                                        <loading v-if="loading[4]" :size="1" class="loading"></loading>
                                        <div v-else>
                                            <div v-if="hasDynamicCodes && metrics.bylang && metrics.bylang.length > 0">
                                                <doughnut-chart :chart-data="langData" :height="200"></doughnut-chart>
                                            </div>
                                            <pro-scans-no-data v-else 
                                                :icon-type="'lang'"
                                                :has-data="hasDynamicCodes">
                                            </pro-scans-no-data>
                                        </div>
                                    </div>
                                </v-card>
                            </v-col>
                        </v-row>

                        <v-row>
                            <!-- Scans by country -->
                            <v-col cols="12" sm="12" md="12" lg="6" :class="[$vuetify.breakpoint.mdAndDown ? 'px-0' : 'ps-0']">
                                <v-card outlined class="smfm-dashboard-card">
                                    <pro-dashboard-toolbar :title="$t('pro.dashboard.scansbycountry')"
                                        :has-data="hasDynamicCodes"
                                        :withInfo="true"
                                        :field="'bycountry'"
                                        @change="update($event, 'bycountry', 5)"
                                        @refresh="onRefresh('bycountry', 5)">
                                    </pro-dashboard-toolbar>                                      
                                    <div class="pa-5">
                                        <loading v-if="loading[5]" :size="1" class="loading"></loading>
                                        <div v-else>
                                            <div v-if="hasDynamicCodes && metrics.bycountry && metrics.bycountry.length > 0">
                                                <doughnut-chart :chart-data="countriesData" :height="200"></doughnut-chart>
                                            </div>
                                            <pro-scans-no-data v-else 
                                                :icon-type="'location'"
                                                :has-data="hasDynamicCodes">
                                            </pro-scans-no-data>
                                        </div>
                                    </div>
                                </v-card>
                            </v-col>
                            <!-- Scans by city -->
                            <v-col cols="12" sm="12" md="12" lg="6" :class="[$vuetify.breakpoint.mdAndDown ? 'px-0' : 'pe-0']">
                                <v-card outlined class="smfm-dashboard-card">
                                    <pro-dashboard-toolbar :title="$t('pro.dashboard.scansbycity')"
                                        :has-data="hasDynamicCodes"
                                        :withInfo="true"
                                        :field="'bycity'"
                                        @change="update($event, 'bycity', 6)"
                                        @refresh="onRefresh('bycity', 6)">
                                    </pro-dashboard-toolbar>                                        
                                    <div class="pa-5">
                                        <loading v-if="loading[6]" :size="1" class="loading"></loading>
                                        <div v-else>
                                            <div v-if="hasDynamicCodes && metrics.bycity && metrics.bycity.length > 0">
                                                <bar-chart :chart-data="citiesData" :height="200"></bar-chart>
                                            </div>
                                            <pro-scans-no-data v-else 
                                                :icon-type="'location'"
                                                :has-data="hasDynamicCodes">
                                            </pro-scans-no-data>
                                        </div>
                                    </div>
                                </v-card>
                            </v-col>
                        </v-row>
                    </div>
                </v-card>
            </v-col>
            <!-- Most popular -->
            <v-col v-if="userPlan.active" md="12" lg="4" cols="12" class="smfm-pro-cards pe-0 pb-4">
                <v-card flat :tile="$vuetify.breakpoint.smAndDown" class="smfm-dashboard-card most-popular">
                    <pro-dashboard-toolbar :title="$t('pro.dashboard.mostpopular')"
                        :has-data="hasDynamicCodes"
                        :field="'mostscanned'"
                        @change="update($event, 'mostscanned', 7)"
                        @refresh="onRefresh('mostscanned', 7)">
                    </pro-dashboard-toolbar>  
                    <v-card-text class="d-flex justify-center align-center px-5 py-0">
                        <loading v-if="loading[7]" :size="1" class="my-16"></loading>
                        <div v-else class="smfm-w-100">
                            <div v-if="hasDynamicCodes && metrics.mostscanned && metrics.mostscanned.length > 0">    
                                <div v-for="(row, index) in metrics.mostscanned" 
                                    :key="`ms_${index}`" @click="goToPage(row)"
                                    class="d-flex justify-start align-center most-popular-row"
                                    :class="{'deleted': row.deleted}">
                                    <div class="secondary--text" style="min-width: 20px;">{{ index + 1 }}</div>
                                    <pro-qr-image 
                                        :qrurl="row.qrurl" 
                                        :ref="'code'+index"
                                        class="mx-5">
                                    </pro-qr-image>
                                    <div>
                                        <div class="text--primary font-weight-medium">
                                            {{ row.codename ? row.codename : `${row.codeid} (${$t('pro.dashboard.deleted')})` }}
                                        </div>
                                        <div>
                                            <span class="text--primary">{{row.cnt}} {{$t('pro.dashboard.scanscnt')}} </span>
                                            <span class="secondary--text">({{row.cntunique}} {{$t('pro.dashboard.uniquecnt')}})</span>
                                        </div>
                                    </div>
                                </div> 
                            </div>
                            <pro-scans-no-data v-else 
                                :icon-type="'total'"
                                :has-data="hasDynamicCodes"
                                :has-create-btn="true">
                            </pro-scans-no-data>
                        </div>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

        <!-- Last scans table -->
        <v-card v-if="userPlan.active" 
            outlined :tile="$vuetify.breakpoint.smAndDown" class="smfm-dashboard-card">
            <pro-dashboard-toolbar :title="$t('pro.dashboard.scanslast')"
                :has-data="hasDynamicCodes"
                :withList="false" :withCSV="true"
                :scans="metrics.scans"
                @refresh="onRefresh('scans', 8)">
            </pro-dashboard-toolbar> 
            <v-card-text class="d-flex justify-center align-center"
                :class="[!loading[8] ? 'pa-5' : 'py-13']">
                <loading v-if="loading[8]" :size="1" class="my-10"></loading>
                <pro-scans-list v-else :rows="metrics.scans"></pro-scans-list>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
    import { mapActions, mapGetters } from "vuex";
    import userMixin from "@/mixins/userMixin";
    import customIcons from "@/mixins/customIcons";
    import chartMixin from "@/mixins/chartMixin";
    import ProSubscriptionInfo from "@/components/pro/settings/ProSubscriptionInfo";
    import ProScansList from "@/components/pro/lists/ProScansList";
    import ProDashboardToolbar from "@/components/pro/dashboard/ProDashboardToolbar";
    import Loading from "@/components/common/Loading";
    import BarChart from "@/components/chart/BarChart";
    import DoughnutChart from "@/components/chart/DoughnutChart";
    import StackedBarChart from "@/components/chart/StackedBarChart";
    import ProTimezone from "@/components/pro/dashboard/ProTimezone";
    import ProQrImage from "@/components/pro/ProQrImage";
    import ProScansNoData from "@/components/pro/dashboard/ProScansNoData";
    import ProChartLegend from "@/components/pro/dashboard/ProChartLegend";
    import ProStartingTrial from "./ProStartingTrial";

    export default {
        name: "ProDashboard",
        components: {
            ProStartingTrial,
            ProSubscriptionInfo, 
            ProScansList, 
            Loading, 
            BarChart, 
            DoughnutChart, 
            StackedBarChart, 
            ProDashboardToolbar,
            ProTimezone,
            ProQrImage,
            ProScansNoData,
            ProChartLegend,
        },
        mixins: [ userMixin, customIcons, chartMixin ],
        data() {
            return {
                metrics: {
                    counts: {},
                    scans: [],
                },
                loading: {},
                loadingKeys: [...Array(9).keys()],
                scanstimelineData: null,
                monthsData: null,
                hoursData: null,
                osData: null,
                langData: null,
                countriesData: null,
                citiesData: null,
                timezone: null
            }
        },
        computed: {
            ...mapGetters(['userlogos', 'usertemplates', 'userpresets']),
            ...mapGetters('dashboard_pro', ['statsParams']),
            hasDynamicCodes() { return !!this.metrics.counts.dynamic },
            mainStats() {
                return [
                    { type: 'dynamiccodes', text: this.$t('pro.dynamiccodes'), name: 'dynamic', value: this.metrics.counts.dynamic },
                    { type: 'staticcodes', text: this.$t('pro.staticcodes'), name: 'static', value: this.metrics.counts.static },
                    { type: 'logos', text: this.$t('pro.library.logos.title'), name: 'library', value: this.userlogos.length },
                    { type: 'templates', text: this.$t('pro.library.templates.title'), name: 'library', value: this.usertemplates.length },
                    { type: 'presets', text: this.$t('pro.library.presets.title'), name: 'library', value: this.userpresets.length },
                ]
            },
        },
        created() {
            if (this.userPlan.active) {
                this.updateMetrics('counts')
                    .then(() => this.hasDynamicCodes && this.updateMetrics())
            }
        },
        mounted() {
            this.timezone = this.$store.getters['dashboard_pro/getSelectedParam']({ field: 'byhour', type: 'tz' });
        },
        methods: {
            ...mapActions('dashboard_pro', ['setStatsParam', 'setHasDynamicCodes']),
            goToPage(row) {
                if (!row.deleted) {
                    this.$router.push({ name: "codestats", params: { codeid: row.codeid }});
                } else {
                    return;
                }
            },
            updateMetrics(metric = null, loading = null) {
                if (loading === null) {
                    this.loading = this.loadingKeys.map(() => true);
                } else {
                    this.loading[loading] = true;
                }
                const url = metric ? `pro/stats/${metric}` : 'pro/stats';
                return this.authPost({url, params: this.statsParams})
                    .then(resp => {
                        this.metrics = {...this.metrics, ...resp.data};
                        this.setHasDynamicCodes(!!this.metrics.counts.dynamic);
                        // Scans timeline
                        if (this.metrics.total && this.metrics.total[0] && this.metrics.total[0].scanstimeline && this.metrics.total[0].scanstimeline.length > 0) {
                            const bin = this.metrics.total[0].bin
                            const scanstimelineLabels = this.metrics.total[0].scanstimeline.map(res => this.getFormattedBin(res.bin, bin));
                            this.scanstimelineData = {
                                labels: scanstimelineLabels,
                                datasets: this.getStackedChartDataset(this.metrics.total[0].scanstimeline)
                            };
                        }
                        // Scans by months
                        if (this.metrics.bymonth && this.metrics.bymonth.length > 0) {
                            const monthsLabels = this.metrics.bymonth.map(month => this.getMonthName(month.month - 1));
                            this.monthsData = {
                                labels: monthsLabels,
                                datasets: this.getStackedChartDataset(this.metrics.bymonth)
                            };
                        }
                        // Scans by hour
                        if (this.metrics.byhour && this.metrics.byhour.length > 0) {
                            const hoursLabels = this.metrics.byhour.map(hour => this.getFormattedTime(hour.hour));
                            this.hoursData = {
                                labels: hoursLabels,
                                datasets: this.getStackedChartDataset(this.metrics.byhour)
                            };
                        }
                        // Scans by OS
                        if (this.metrics.byos && this.metrics.byos.length > 0) {
                            this.osData = this.getDoughnutChartData(this.metrics.byos, 'os');
                        }
                        // Scans by language
                        if (this.metrics.bylang && this.metrics.bylang.length > 0) {
                            this.langData = this.getDoughnutChartData(this.metrics.bylang, 'lang');
                        }
                        // Scans by countries
                        if (this.metrics.bycountry && this.metrics.bycountry.length > 0) {
                            this.countriesData = this.getDoughnutChartData(this.metrics.bycountry, 'country');
                        }
                        // Scans by cities
                        if (this.metrics.bycity && this.metrics.bycity.length > 0) {
                            const citiesLabels = this.metrics.bycity.map(city => {
                                return city.isother ? this.$t('pro.dashboard.other') : city.city;
                            });
                            const citiesDataset = this.metrics.bycity.map(city => city.cnt);
                            this.citiesData = {
                                labels: citiesLabels,
                                datasets: [
                                    {
                                        backgroundColor: "#12C7D0",
                                        hoverBackgroundColor: "#03949A",
                                        maxBarThickness: 26,
                                        data: citiesDataset
                                    }
                                ]
                            };
                        }
                        if (!loading) {
                            this.loading = this.loadingKeys.map(() => false);
                        } else {
                            this.loading[loading] = false;
                        }
                    })
            },
            update(value, field, loading) {
                this.setStatsParam({ value, field, type: 'timelimit' });
                this.updateMetrics(field, loading);
            },
            changeTimezone(value, field, loading) {
                this.timezone = value;
                this.setStatsParam({ value, field, type: 'tz' });
                this.updateMetrics(field, loading);
            },
            onRefresh(field, loading) {
                this.updateMetrics(field, loading);
            }
        }
    }
</script>

<style scoped lang="scss">
.dashboard {
    .main-stats-grid {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
        grid-gap: 16px;
        justify-items: center;
    }
    .main-stat {
        width: 100%;
        border: 1px solid #EEEEEE;
        border-radius: 12px;
        text-decoration: none;
        color: #393E46;
        &:hover {
            border: 1px solid #00ADB5;
        }
        .count {
            font-weight: 600;
            font-size: 22px;
            line-height: 33px;
        }
    }
    .smfm-dashboard-card {
        border-radius: 12px;
        &.most-popular {
            border-radius: 4px;
            .v-toolbar {
                &.v-sheet {
                    background: #fff !important;
                }
            }
        }
        .total-scans {
            width: 100%;
            .title {
                font-weight: 600;
                font-size: 42px !important;
                line-height: 63px;
                color: #21252C;
                @media(max-width: 960px) {
                    font-size: 22px !important;
                    line-height: 33px;
                }
            }
        }
        .most-popular-row {
            padding: 20px 20px 20px 10px;
            text-decoration: none;
            &:not(:last-child) {
                box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.1);
            }
            &:hover {
                background: #FAFAFA;
                cursor: pointer;
            }
            &.deleted {
                opacity: .6;
                pointer-events: none;
            }
        }
        .loading {
            height: 200px;
            &.lg {
                min-height: 310px;
            }
            &.xl {
                min-height: 385px;
                @media(max-width: 960px) {
                    min-height: 355px;
                }
            }
        }
    }
}
</style>
