<template>
    <v-dialog 
        v-model="showdialog"
        scrollable
        max-width="750px">
        <v-card class="start-trial">
            <div class="d-flex justify-center align-center header">
                {{ $t('pro.dashboard.welcomeheader') }}
            </div>
            <v-card-text>
                <div class="text--primary mb-3" v-html="$t('pro.dashboard.welcometotrial')"></div>
                <div class="secondary--text mb-7" v-html="$t('pro.dashboard.welcomepref')"></div>
                <pro-preferences :form-only="true" ref="prefs"></pro-preferences>
            </v-card-text>
            <v-card-actions class="d-flex justify-end align-center pt-5"
                :class="[$vuetify.breakpoint.smAndDown ? 'px-5 pb-7' : 'px-10 pb-10']">
                <v-btn text color="secondary" @click="closeDialog">
                    {{ $t('app.cancel') }}
                </v-btn>
                <v-btn class="smfm-primary-btn smfm-md-btn" @click="proceed">
                    {{ $t('pro.continue') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import userMixin from "@/mixins/userMixin";
    import customIcons from "@/mixins/customIcons";
    import ProPreferences from "@/components/pro/settings/ProPreferences";

    export default {
        name: "ProStartingTrial",
        components: { ProPreferences },
        mixins: [ userMixin, customIcons ],
        data() {
            return {
                showdialog: false
            }
        },
        created() {
            if (this.userPlan.canStartTrial) {
                this.showdialog = true
                this.startTrial()
            }
        },
        methods: {
            closeDialog() {
                this.showdialog = false
            },
            startTrial() {
                if (!this.userPlan.canStartTrial) return
                this.authPost({url: 'billing/start-trial'})
                    .then(() => this.refreshUser())
                    .then(() => this.$refs.prefs.changePreferences())
                    .catch(err => {
                        console.error(err)
                        this.refreshUser()
                    })
            },
            proceed() {
                this.$refs.prefs.changePreferences()
                this.closeDialog()
            }
        }
    }
</script>

<style scoped lang="scss">
.start-trial {
    .header {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        background-image: url('/images/pro/start-trial.svg'), linear-gradient(88.75deg, #03949A 0%, #12C7D0 100%);
        font-size: 26px;
        line-height: 39px;
        color: #fff;  
        padding: 55px 20px;   
        @media(max-width: 960px) {
            font-size: 16px;
            line-height: 24px;
            padding: 30px 20px;  
        }   
    }
    .v-card__text {
        font-size: 16px;
        line-height: 24px;
        color: #909295 !important;
        padding: 30px 40px !important;
        @media(max-width: 960px) {
            padding: 20px !important;
        }
    }
    .v-card__actions {
        box-shadow: inset 0px 2px 0px #eeeeee;
    }
}
</style>