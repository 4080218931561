<template>
    <div>
        <v-overlay v-if="islogout" color="#ffffff" :light="true" :dark="false" :opacity="1">
            <div style="text-align: center">
                <v-progress-circular indeterminate :aria-label="$t('app.loading')"></v-progress-circular>
                <div>
                    <span v-if="islogout">Good bye...</span>
                    <span v-else>Please wait...</span>
                </div>
            </div>
        </v-overlay>
    </div>
</template>

<script>
    import userMixin from "@/mixins/userMixin";

    export default {
        name: "ProLogin",

        mixins: [userMixin],

        computed: {
            islogout() { return this.$route.name === 'logout' }
        },

        created() {
            if (!this.$route.meta.skiplogin) {
                this.requireLogin()
            }

            if (this.$route.name === 'loginCallback') {
                const expectedCallback = process.env.VUE_APP_PRO_URL + '/login_callback',
                      realCallback = `${window.location.protocol}//${window.location.host}${window.location.pathname}`
                if (process.env.VUE_APP_PRO_LOGIN_CALLBACK && process.env.VUE_APP_PRO_LOGIN_CALLBACK !== expectedCallback &&
                    realCallback !== expectedCallback) {
                    // Mega-hack for dev site.
                    window.location.href = expectedCallback + window.location.search
                    return
                }

                this.loginCallback(window.location.search)
                    .then(() => this.$router.push('/'))

            } else if (this.islogout) {
                this.logout()
            }
        },

        watch: {
            islogout(v) {
                if (v) {
                    this.logout()
                }
            }
        }
    }
</script>

<style scoped>

</style>
