<template>
    <v-container class="contact-us" :class="{'pa-0': isPro || $vuetify.breakpoint.smAndDown}">
        <v-row>
            <v-col :md="isPro ? 12 : 7" lg="7" cols="12"
                class="contact-cards ps-0 pb-4" :class="{'pro-account': isPro}">
                <v-card flat :class="[$vuetify.breakpoint.smAndDown ? 'pa-5' : 'px-10 py-7']">
                    <h1 class="smfm-page-title ma-0">{{ $t('app.contact.contactus') }}</h1>
                    <div class="contact-subtitle secondary--text"
                        :class="[$vuetify.breakpoint.smAndDown ? 'mb-5' : 'mb-10']">
                        {{ $t('app.contact.contact') }}
                    </div>
                    <h2 class="smfm-page-heading"
                        :class="[$vuetify.breakpoint.smAndDown ? 'mb-5' : 'mb-9']">
                        {{ $t('app.contact.sendmessage') }}:
                    </h2>
                    <v-form ref="form" v-model="formValid">
                        <v-row>
                            <v-col cols="12" sm="12" class="pa-0">
                                <v-text-field outlined name="name" :label="$t('app.contact.yourname')" dir="ltr"
                                    v-model="contactForm.name" :rules="[requiredRule]">
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="12" class="pa-0">
                                <v-text-field outlined name="email" :label="$t('app.contact.youremail')" dir="ltr"
                                    v-model="contactForm.email" :rules="[requiredRule, emailRule]"
                                    :readonly="isPro">
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="12" class="pa-0">
                                <v-text-field outlined name="subject" :label="$t('app.contact.messagesubject')" dir="ltr"
                                    v-model="contactForm.subject" :placeholder="$t('app.contact.messagesubjectdefault')">
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="12" class="pa-0">
                                <v-textarea outlined rows="4" name="message" :label="$t('app.contact.yourmessage')" dir="ltr"
                                    v-model="contactForm.message" :rules="[requiredRule]">
                                </v-textarea>
                            </v-col>
                        </v-row>
                        <div v-if="captchaKey && !isPro" class="mb-7">
                            <vue-recaptcha
                                ref="recaptcha"
                                :sitekey="captchaKey"
                                :language="currentLang"
                                :loadRecaptchaScript="true"
                                @verify="onCaptchaVerified"
                                @expired="onCaptchaExpired">
                            </vue-recaptcha>
                        </div>
                        <v-btn class="smfm-primary-btn smfm-lg-btn"
                            :block="$vuetify.breakpoint.xsOnly"
                            :disabled="!(formValid && (isPro ? true : captchaVerified))"
                            @click="submit()">
                            <loading v-if="loading"></loading>
                            <span v-else>{{ $t('app.contact.submit') }}</span>
                        </v-btn>
                    </v-form>
                </v-card>
            </v-col>
            <v-col :md="isPro ? 12 : 5" lg="5" cols="12" 
                class="contact-cards pe-0" :class="{'pro-account': isPro}">
                <v-card flat :tile="$vuetify.breakpoint.smAndDown"
                    class="contact-info mb-10" :class="[$vuetify.breakpoint.smAndDown ? 'pa-5' : 'px-10 py-7']">
                    <h2 class="smfm-page-heading mb-8">{{ $t('app.contact.contactinfo') }}:</h2>
                    <!-- Address -->
                    <div v-if="contactAddress" :class="[$vuetify.breakpoint.smAndDown ? 'mb-5' : 'mb-7']">
                        <a :href="`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(contactAddress)}`" target="_blank" rel="noopener">
                            <div class="d-flex align-center">
                                <div class="d-flex justify-center align-center contact-icon-wrap me-5">
                                    <v-icon color="white">{{ iconMarker }}</v-icon>
                                </div>
                                <div>{{ contactAddress }}</div>
                            </div>
                        </a>
                    </div>
                    <!-- Email -->
                    <div v-if="contactEmail" :class="[$vuetify.breakpoint.smAndDown ? 'mb-5' : 'mb-7']">
                        <a :href="`mailto:${contactEmail}`" target="_blank">
                            <div class="d-flex align-center">
                                <div class="d-flex justify-center align-center contact-icon-wrap me-5">
                                    <v-icon color="white">{{ iconEmailOutline }}</v-icon>
                                </div>
                                <div>{{ contactEmail }}</div>
                            </div>
                        </a>
                    </div>
                    <!-- Socials -->
                    <div v-for="social in socials" :key="social.type"
                        :class="[$vuetify.breakpoint.smAndDown ? 'mb-5' : 'mb-7']">
                        <a :href="social.link" target="_blank" rel="noopener" v-if="!social.ishidden">
                            <div class="d-flex align-center">
                                <div class="d-flex justify-center align-center contact-icon-wrap me-5">
                                    <v-icon color="white">{{ social.icon }}</v-icon>
                                </div>
                                <div>{{ social.title }}</div>
                            </div>
                        </a>
                    </div>
                </v-card>
            </v-col>
        </v-row>
        <!-- Notifications dialog -->
        <notifications-dialog
            :dialogVisible="notificationsDialogVisible"
            @close="notificationsDialogVisible=false">
        </notifications-dialog>
    </v-container>
</template>

<script>
    import { mdiEmailOutline, mdiInstagram, mdiMapMarkerOutline } from "@mdi/js";
    import axios from "axios";
    import EventBus from '@/plugins/EventBus.js';
    import VueRecaptcha from 'vue-recaptcha';
    import titleMixin from "@/mixins/titleMixin";
    import validation from "@/mixins/validation";
    import customIcons from "@/mixins/customIcons";
    import Loading from "@/components/common/Loading";
    import NotificationsDialog from "@/components/about/NotificationsDialog";

    export default {
        name: "AboutContactUs",
        components: { VueRecaptcha, Loading, NotificationsDialog },
        mixins: [ titleMixin, validation, customIcons ],
        props: {
            user: {
                type: Object,
                default: () => {}             
            }
        },
        data() {
            return {
                formValid: true,
                contactForm: {
                    name: '',
                    email: '',
                    subject: '',
                    message: '',
                },
                captchaVerified: false,
                recaptchatoken: null,
                notificationsDialogVisible: false,
                loading: false,
            }
        },
        computed: {
            contactAddress() {
                return process.env.VUE_APP_CONTACT_ADDRESS
            },
            contactEmail() {
                return process.env.VUE_APP_CONTACT_EMAIL
            },
            captchaKey() {
                return process.env.VUE_APP_RECAPTCHA_SITE_KEY;
            },
            iconEmailOutline() { return mdiEmailOutline },
            iconMarker() { return mdiMapMarkerOutline },
            socials() {
                return [
                    {
                        type: 'twitter',
                        icon: this.iconTwitter,
                        title: 'Twitter',
                        link: 'https://twitter.com/scanmefindme',
                        ishidden: true,
                    },
                    {
                        type: 'instagram',
                        icon: mdiInstagram,
                        title: 'Instagram',
                        link: 'https://www.instagram.com/scanmefindme',
                        ishidden: true,
                    },
                    {
                        type: 'reddit',
                        icon: this.iconReddit,
                        title: 'Reddit',
                        link: 'https://www.reddit.com/user/scanmefindme',
                        ishidden: true,
                    },
                    {
                        type: 'github',
                        icon: this.iconGithub,
                        title: 'Github',
                        link: 'https://www.github.com/scanmefindme',
                    },
                ];
            },
        },
        methods: {
            onCaptchaVerified(resp) {
                if (resp) {
                    this.captchaVerified = true
                    this.recaptchatoken = resp
                }
            },
            onCaptchaExpired() {
                this.$refs.recaptcha.reset();
            },
            submit() {
                this.loading = true;
                if (this.isPro) {
                    this.$emit('proContact', this.contactForm);
                } else {
                    axios.post(process.env.VUE_APP_API + '/demo/contact', {...this.contactForm, recaptchatoken: this.recaptchatoken})
                        .then(() => {
                            EventBus.$emit('showNotification', {
                                type: 'success',
                                title: this.$t('app.contact.thankyou'),
                                subtitle: this.$t('app.contact.getback'),
                                buttontext: this.$t('app.contact.gotit')
                            });
                            this.notificationsDialogVisible = true;
                            this.$refs.form.reset();
                            this.loading = false;
                        })
                        .catch(() => {
                            EventBus.$emit('showNotification', {
                                type: 'error',
                                title: this.$t('app.contact.sorry'),
                                subtitle: this.$t('app.contact.smthwrong'),
                                buttontext: this.$t('app.contact.illtry')
                            });
                            this.notificationsDialogVisible = true;
                            this.loading = false;
                        })
                }
            }
        },
        created() {
            this.setTitle(this.$t('app.contact.contactus'))
            if (this.user) {
                this.contactForm.name = this.user.name;
                this.contactForm.email = this.user.email;
            }
        },
        mounted() {
            EventBus.$on('resetProForm', () => {
                this.contactForm.subject = '';
                this.contactForm.message = '';
                this.$refs.form.resetValidation();
                this.loading = false;
            })
        },
        beforeDestroy() {
            EventBus.$off('resetProForm');
        }
    }
</script>

<style scoped lang="scss">
/*! purgecss start ignore */

.contact-us {
    a {
        color: inherit;
        text-decoration: none !important;
    }
    .contact-subtitle {
        max-width: 420px;
    }
    .contact-info {
        background: #00ADB5;
        color: #fff;
        .smfm-page-heading {
            color: #fff;
        }
        .contact-icon-wrap {
            width: 44px;
            min-width: 44px;
            height: 44px;
            background: rgba(255, 255, 255, 0.1);
            border-radius: 30px;
        }
    }
    .contact-cards {
        padding: 0 8px;
        @media(max-width: 960px) {
            padding: 0;
        }
        &.pro-account {
            @media(max-width: 1264px) {
                padding: 0;
            } 
        }
    }
    .page-heading {
        font-weight: normal;
    }
}
/*! purgecss end ignore */
</style>