<template>
  <div class="d-flex justify-space-between align-stretch no-wrap pricing-wrap">
    <span v-if="!isPro" class="hidden-block"></span>
    <div v-for="plan in plans" :key="plan.shortname" class="smfm-pricing-block"
      :class="{'advanced': !isPro && plan.name === 'Advanced'}">
      <div class="d-flex justify-start align-center mb-5">
        <h2 class="title">{{ plan.name }}</h2>
        <div v-if="plan.price > 0" class="pricing-badge">PRO</div>
      </div>
      <!-- Price -->
      <div class="d-flex justify-start align-end mb-3">
        <span class="price">
          {{ formattedMonthPrice(plan.price) }}
        </span>
        <span v-if="plan.price > 0" class="secondary--text">/ {{ $t('app.pricing.pmonth') }}</span>
      </div>
      <div class="per-year black--text" :class="{'lg': lang !== 'en'}">
        <div v-if="plan.price > 0"
          v-html="$t('app.pricing.billedannually', {price: formattedYearPrice(plan.price)})">
        </div>
        <div v-else>&nbsp;</div>
      </div>
      <!-- Start buttons -->
      <div v-if="!isPro">
        <demo-launch-pro v-if="plan.price > 0">
          <template slot-scope="{proceed}">
            <v-btn block class="smfm-primary-btn smfm-lg-btn my-7" @click="proceed()">
              {{ $t('app.pricing.starttrial') }}
            </v-btn>
          </template>
        </demo-launch-pro>
        <v-btn v-else block class="smfm-primary-btn smfm-lg-btn my-7 text-center" :to="'/'+lang">
          {{ $t('app.pricing.startusing' )}}
        </v-btn>
      </div>
      <v-btn v-else block class="smfm-primary-btn smfm-lg-btn my-7"
        @click="$emit('connectPlan', plan)">
        {{ $t('app.pricing.connectplan' )}}
      </v-btn>
      <!-- Features -->
      <div class="d-flex flex-column">
        <div v-for="(feature, idxf) in features" :key="'f'+idxf" :class="hasfeature(plan, feature) || feature.labelnovalue ? [] : ['d-none']">
          <div v-if="hasfeature(plan, feature)" class="d-flex justify-start align-start mb-5 feature">
            <v-icon>{{ iconHasfeature }}</v-icon>
            <span v-html="getLabel(plan, feature)"></span>&nbsp;
            <span v-if="isunlimited(plan, feature) && feature.showunlimited" class="unlimited">{{ $t('app.pricing.unlimited' )}}</span>
          </div>
          <div v-else-if="feature.labelnovalue" class="d-flex justify-start align-center mb-5 feature">
            <v-icon>{{ iconHasnofeature }}</v-icon>
            <span>{{ feature.labelnovalue }}</span>
          </div>
        </div>
      </div>
    </div>
    <span v-if="!isPro" class="hidden-block"></span>
  </div>
</template>

<script>
    import { mapGetters } from "vuex";
    import { mdiCheckboxMarkedCircleOutline, mdiClose } from '@mdi/js';
    import DemoLaunchPro from "@/components/demo/DemoLaunchPro";
    const UNLIMITED = 'unlimited';

    export default {
        name: "AboutPricingPlans",
        components: { DemoLaunchPro },
        props: {
            plans: {
                type: Array,
                default: () => {}
            },
            features: {
                type: Array,
                default: () => {}
            },
        },
        computed: {
            ...mapGetters("currency", ["currentCurrency"]),
            lang() { return this.$route.params.lang },
            iconHasfeature() { return mdiCheckboxMarkedCircleOutline },
            iconHasnofeature() { return mdiClose },
        },
        methods: {
            hasfeature(plan, feature) {
                return plan.features[feature.code] && (""+plan.features[feature.code]).length
            },
            isunlimited(plan, feature) {
                return plan.features[feature.code] === UNLIMITED;
            },
            getLabel(plan, feature) {
                if (this.isunlimited(plan, feature)) {
                    return feature.label
                } else {
                    let no = plan.features[feature.code],
                        label = (no === 1 && 'label1' in feature) ? feature.label1 : feature.label,
                        noformatted = no.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    return `<strong>${noformatted}</strong> ${label}`
                }
            },
            formattedMonthPrice(price) {
                return `${this.currentCurrency.symbol} ${this.getPlanMonthPrice(price, this.currentCurrency.rate)}`
            },
            formattedYearPrice(price) {
                return `${this.currentCurrency.symbol} ${this.getPlanYearPrice(price, this.currentCurrency.rate)}`
            },          
        }
    }
</script>

<style scoped lang="scss">
.pricing-wrap {
  overflow-x: auto;
  &::-webkit-scrollbar {
    display: none;
  }
  .hidden-block {
    min-width: 20px;
  }
  .smfm-pricing-block {
    max-width: 304px;
    min-width: 284px;
    border: 1px solid #C7C8CA;
    border-radius: 20px;
    padding: 40px 20px;
    margin: 10px;
    &:first-of-type {
      margin-inline-start: 0;
    }
    &:last-of-type {
      margin-inline-end: 0;
    }
    .title {
      font-weight: 500;
      font-size: 20px;
      line-height: 30px;
      color: #393E46;
      margin-inline-end: 10px;
    }
    .smfm-primary-btn {
      border-radius: 6px;
      padding: 2px 12px;
    }
    .pricing-badge {
      background: #12C7D0;
      color: #fff;
      border-radius: 6px;
      padding: 2px 12px;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
    }
    .price {
      font-weight: 600;
      font-size: 42px;
      line-height: 1;
      color: #21252C;
      margin-inline-end: 10px;
    }
    .per-year {
      min-height: 24px;
      &.lg {
        min-height: 48px;
      }
    }
    .feature {
      font-size: 16px;
      line-height: 24px;
      span {
        color: #909295;
      }
      .v-icon {
        margin-inline-end: 14px;
        color: #21252C;
      }
    }
    &.advanced {
      background: #00ADB5;
      border: 1px solid #00ADB5;
      * {
        color: #fff !important;
      }
      .pricing-badge {
        background: #fff;
        color: #03949A !important;
      }
      .smfm-primary-btn {
        background: #21252C !important;
        color: #fff !important;
        &:hover {
          background: #393E46 !important;
        }
      }
    }
    &:hover {
      border-color: #00ADB5;
    }
  }
}
</style>