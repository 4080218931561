<template>
    <div>
        <div v-if="!codeid">
            <v-row>
                <v-col cols="12" sm="12" md="6" :class="[$vuetify.breakpoint.smAndDown ? 'pa-0' : 'py-0 ps-0']">
                    <v-text-field outlined name="firstname" :label="$t('app.forms.firstname')" v-model="v.firstname"
                        :rules="requiredNameVcardRules"
                        @change="$emit('change')" ref="maininput">
                    </v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="6" :class="[$vuetify.breakpoint.smAndDown ? 'pa-0' : 'py-0 pe-0']">
                    <v-text-field outlined name="lastname" :label="$t('app.forms.lastname')" v-model="v.lastname"
                        :rules="requiredNameVcardRules"
                        @change="$emit('change')">
                    </v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" sm="12" class="pa-0">
                    <v-text-field outlined name="organisation" :label="$t('app.forms.organisation')" v-model="v.org"
                        :rules="requiredNameVcardRules"
                        @change="$emit('change')">
                    </v-text-field>
                </v-col>
            </v-row>
        </div>

        <div v-else>
            <input-files :codeid="codeid" filearea="logo" value="thumb" :max-files="1"
                       :fixed-display-type="true" :label="$t('app.forms.contactlogo')"
                       :files="f.logo" 
                       @change="$emit('change')"
                       @updated="$emit('change')">
            </input-files>
            <!-- First name & Last name -->
            <v-row>
                <v-col cols="12" sm="12" md="6" lg="6" :class="[$vuetify.breakpoint.smAndDown ? 'pa-0' : 'py-0 ps-0']">
                    <v-text-field outlined name="firstname" :label="$t('app.forms.firstname')" v-model="v.firstname"
                                  :rules="requiredNameVcardRules" :prepend-icon="iconFirstname"
                                  @change="$emit('change')"></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="6" lg="6" :class="[$vuetify.breakpoint.smAndDown ? 'pa-0' : 'py-0 pe-0']">
                    <v-text-field outlined name="lastname" :label="$t('app.forms.lastname')" v-model="v.lastname"
                                  :rules="requiredNameVcardRules" :prepend-icon="iconLastname"
                                  @change="$emit('change')"></v-text-field>
                </v-col>
            </v-row>
            <!-- Organization & job title -->
            <v-row>
                <v-col cols="12" sm="12" md="6" lg="6" :class="[$vuetify.breakpoint.smAndDown ? 'pa-0' : 'py-0 ps-0']">
                    <v-text-field outlined name="organisation" :label="$t('app.forms.organisation')" v-model="v.org"
                                  :rules="requiredNameVcardRules" :prepend-icon="iconOrganisation"
                                  @change="$emit('change')"></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="6" lg="6" :class="[$vuetify.breakpoint.smAndDown ? 'pa-0' : 'py-0 pe-0']">
                    <v-text-field outlined name="title" :label="$t('app.forms.jobtitle')" v-model="v.title"
                                  :prepend-icon="iconTitle"
                                  @change="$emit('change')"></v-text-field>
                </v-col>
            </v-row>
            <!-- Phones -->
            <v-row>
                <v-col cols="12" sm="12" md="6" lg="6" :class="[$vuetify.breakpoint.smAndDown ? 'pa-0' : 'py-0 ps-0']">
                    <v-text-field outlined name="phone1" :label="$t('app.forms.mobilephone')" v-model="v.phone1"
                                  :prepend-icon="iconPhone" dir="ltr"
                                  @change="$emit('change')"></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="6" lg="6" :class="[$vuetify.breakpoint.smAndDown ? 'pa-0' : 'py-0 pe-0']">
                    <v-text-field outlined name="phone2" :label="$t('app.forms.workphone')" v-model="v.phone2"
                                  :prepend-icon="iconPhone" dir="ltr"
                                  @change="$emit('change')"></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="6" lg="6" class="pa-0">
                    <v-text-field outlined name="phone3" :label="$t('app.forms.otherphone')" v-model="v.phone3"
                                  :prepend-icon="iconPhone" dir="ltr"
                                  @change="$emit('change')"></v-text-field>
                </v-col>
            </v-row>
            <!-- Email -->
            <v-row>
                <v-col cols="12" sm="12" class="pa-0">
                    <v-text-field outlined name="email" :label="$t('app.forms.email')" v-model="v.email"
                                  :rules="[emailRule]" :prepend-icon="iconEmail" dir="ltr"
                                  @change="$emit('change')"></v-text-field>
                </v-col>
            </v-row>
            <!-- Website -->
            <v-row>
                <v-col cols="12" sm="12" class="pa-0">
                    <v-text-field outlined name="url" :label="$t('app.forms.website')" v-model="v.url"
                                  :rules="[urlRule]" :prepend-icon="iconUrl" dir="ltr"
                                  @change="$emit('change')"></v-text-field>
                </v-col>
            </v-row>
            <!-- Address -->
            <v-row>
                <v-col cols="12" sm="12" class="pa-0">
                    <v-text-field outlined name="address" :label="$t('app.forms.address')" v-model="v.address"
                                  @change="$emit('change')"
                                  id="address" :prepend-icon="iconAddress"></v-text-field>
                </v-col>
            </v-row>
            <!-- Notes -->
            <v-row>
                <v-col cols="12" sm="12" class="pa-0">
                    <v-textarea outlined rows="2" :label="$t('app.forms.notes')" name="notes" v-model="v.notes"
                                :prepend-icon="iconText"
                                @change="$emit('change')"></v-textarea>
                </v-col>
            </v-row>
            <!-- Social networks -->
            <div class="text-body-1" v-html="$t('pro.forms.socialnetworks')"></div>
            <div class="smfm-pro-hint mb-7" v-html="$t('pro.forms.socialnetworksdesc')"></div>
            <v-row>
                <v-col cols="12" sm="12" md="6" lg="6" :class="[$vuetify.breakpoint.smAndDown ? 'pa-0' : 'py-0 ps-0']">
                    <v-text-field outlined name="instagram" label="Instagram" v-model="v.instagram" dir="ltr"
                                  :prepend-icon="iconInstagram" @change="$emit('change')"></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="6" lg="6" :class="[$vuetify.breakpoint.smAndDown ? 'pa-0' : 'py-0 pe-0']">
                    <v-text-field outlined name="snapchat" label="Snapchat" v-model="v.snapchat" dir="ltr"
                                  :prepend-icon="iconSnapchat" @change="$emit('change')"></v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" sm="12" md="6" lg="6" :class="[$vuetify.breakpoint.smAndDown ? 'pa-0' : 'py-0 ps-0']">
                    <v-text-field outlined name="twitter" label="Twitter" v-model="v.twitter" dir="ltr"
                                  :prepend-icon="iconTwitter" @change="$emit('change')"></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="6" lg="6" :class="[$vuetify.breakpoint.smAndDown ? 'pa-0' : 'py-0 pe-0']">
                    <v-text-field outlined name="facebook" label="Facebook" v-model="v.facebook" dir="ltr"
                                  :prepend-icon="iconFacebook" @change="$emit('change')"></v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" sm="12" md="6" lg="6" :class="[$vuetify.breakpoint.smAndDown ? 'pa-0' : 'py-0 ps-0']">
                    <v-text-field outlined name="tiktok" label="Tiktok" v-model="v.tiktok" dir="ltr"
                                  :prepend-icon="iconTiktok" @change="$emit('change')"></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="6" lg="6" :class="[$vuetify.breakpoint.smAndDown ? 'pa-0' : 'py-0 pe-0']">
                    <v-text-field outlined name="whatsapp" label="Whatsapp" v-model="v.whatsapp" dir="ltr"
                                  :prepend-icon="iconWhatsapp" @change="$emit('change')"></v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" sm="12" md="6" lg="6" :class="[$vuetify.breakpoint.smAndDown ? 'pa-0' : 'py-0 ps-0']">
                    <v-text-field outlined name="linkedin" label="Linkedin (individual)" v-model="v.linkedin" dir="ltr"
                                  :prepend-icon="iconLinkedin" @change="$emit('change')"></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="6" lg="6" :class="[$vuetify.breakpoint.smAndDown ? 'pa-0' : 'py-0 pe-0']">
                    <v-text-field outlined name="linkedinorg" label="Linkedin (organisation)" v-model="v.linkedinorg" dir="ltr"
                                  :prepend-icon="iconLinkedin" @change="$emit('change')"></v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" sm="12" md="6" lg="6" :class="[$vuetify.breakpoint.smAndDown ? 'pa-0' : 'py-0 ps-0']">
                    <v-text-field outlined name="pinterest" label="Pinterest" v-model="v.pinterest" dir="ltr"
                                  :prepend-icon="iconPinterest" @change="$emit('change')"></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="6" lg="6" :class="[$vuetify.breakpoint.smAndDown ? 'pa-0' : 'py-0 pe-0']">
                    <v-text-field outlined name="youtube" label="YouTube channel" v-model="v.youtube" dir="ltr"
                                  :prepend-icon="iconYoutube" @change="$emit('change')"></v-text-field>
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script>
    import {
        mdiAccount, mdiAccountOutline, mdiAt, mdiBriefcaseOutline,
        mdiDomain, mdiInstagram, mdiMapMarkerOutline,
        mdiPhone, mdiPinterest, mdiSnapchat, mdiTextBoxOutline, mdiWeb, mdiWhatsapp,
        mdiYoutube
    } from "@mdi/js";
    import validation from "@/mixins/validation";
    import customIcons from "@/mixins/customIcons";
    import InputFiles from "@/components/forms/InputFiles";

    export default {
        name: "DynamicFormContact",
        components: { InputFiles },
        mixins: [ validation, customIcons ],
        props: {
            codeid: null,
        },
        data() {
            return {
                v: {
                    firstname: '',
                    lastname: '',
                    org: '',
                    title: '',
                    phone1: '',
                    phone2: '',
                    phone3: '',
                    email: '',
                    url: '',
                    address: '',
                    notes: '',
                    instagram: '',
                    snapchat: '',
                    twitter: '',
                    facebook: '',
                    tiktok: '',
                    whatsapp: '',
                    linkedin: '',
                    linkedinorg: '',
                    youtube: '',
                    pinterest: '',

                    // TODO: instagram, snapchat, twitter, facebook, tiktok, reddit, whatsapp, linkedin
                    // Disclaimer: examples are absolutely random!!!!
                    // https://www.instagram.com/selenagomez/
                    // https://www.snapchat.com/add/blogilates
                    // https://twitter.com/LMSCloud (works with @ too)
                    // https://www.facebook.com/grammarly
                    // https://www.tiktok.com/@cleaning.19
                    // https://www.reddit.com/r/WorkOnline/ ; https://www.reddit.com/user/scanmefindme/
                    // https://wa.me/1234567789 (with country code, without plus!)
                    // https://www.linkedin.com/in/bill-gates
                    // https://www.linkedin.com/company/lmscloud
                    // https://www.youtube.com/@LMSCloud
                    // https://www.pinterest.es/gilliansarahdesign/
                },
                f: {},
            }
        },
        computed: {
            value: {
                get() {
                    return this.v
                },
                set(newValue) {
                    this.v = newValue
                }
            },
            files: {
                get() {
                    return this.f
                },
                set(newValue) {
                    this.f = newValue
                }
            },
            label() {
                return ('' + this.v.firstname + ' ' + this.v.lastname + ' ' + this.v.org).replace(/ +/, ' ').trim()
            },
            hasName() {
                return ('' + this.v.firstname + this.v.lastname + this.v.org).length > 0
            },
            requiredNameVcardRules() {
                const req = v => !!v || this.$t('app.validation.vcardrequired')
                return !this.hasName ? [req] : []
            },
            iconFirstname() { return mdiAccountOutline },
            iconLastname() { return mdiAccount },
            iconPhone() { return mdiPhone  },
            iconEmail() { return mdiAt },
            iconUrl() { return mdiWeb },
            iconAddress() { return mdiMapMarkerOutline},
            iconText() { return mdiTextBoxOutline },
            iconOrganisation() { return mdiDomain },
            iconTitle() { return mdiBriefcaseOutline },
            iconInstagram() { return mdiInstagram },
            iconSnapchat() { return mdiSnapchat },
            iconWhatsapp() { return mdiWhatsapp },
            iconYoutube() { return mdiYoutube },
            iconPinterest() { return mdiPinterest },
        },
        methods: {
            loadGoogleAPI() {
                if (typeof google === 'object' && typeof google.maps === 'object') {
                    return;        
                } else {
                    let script = document.createElement("script");
                    script.setAttribute('src', `https://maps.googleapis.com/maps/api/js?key=${process.env.VUE_APP_GOOGLE_API}&libraries=places`);
                    document.head.appendChild(script);  
                }
            },
            focusMain() {
                this.$refs.maininput.focus();
                this.$refs.maininput.$el.querySelector('input').select();
            }
        },
        mounted() {
            if (this.codeid) {
                this.loadGoogleAPI();
                /* global google */
                setTimeout(() => {
                    let address = document.getElementById('address');
                    let autocomplete = new google.maps.places.Autocomplete(address, { types: ['geocode'] });
                    autocomplete.addListener("place_changed", () => {
                        const place = autocomplete.getPlace();
                        this.v.address = place.formatted_address;
                        this.$emit('change');
                    });
                }, 3 * 1000);
            }
        },
    }
</script>

<style lang="scss">
input#address {
    &::placeholder {
        color: transparent;
    }
}
</style>
