<template>
  <div>
    <about-contact-us :user="currentUser" @proContact="submit"></about-contact-us>
    <!-- Notifications dialog -->
    <notifications-dialog
      :dialogVisible="notificationsDialogVisible"
      @close="notificationsDialogVisible=false">
    </notifications-dialog>
  </div>
</template>

<script>
    import userMixin from "@/mixins/userMixin";
    import EventBus from '@/plugins/EventBus.js';
    import AboutContactUs from "@/components/about/AboutContactUs";
    import NotificationsDialog from "@/components/about/NotificationsDialog";

    export default {
        name: "ProSupport",
        mixins: [ userMixin ],
        components: { AboutContactUs, NotificationsDialog },
        data() {
            return {
                notificationsDialogVisible: false,
            }
        },
        methods: {
            submit(event) {
                this.authPost({url: 'pro/user/contact', params: event})
                    .then(() => {
                        EventBus.$emit('showNotification', {
                            type: 'success',
                            title: this.$t('app.contact.thankyou'),
                            subtitle: this.$t('app.contact.getback'),
                            buttontext: this.$t('app.contact.gotit')
                        });
                        this.notificationsDialogVisible = true;
                        EventBus.$emit('resetProForm');
                    })
                    .catch(() => {
                        EventBus.$emit('showNotification', {
                            type: 'error',
                            title: this.$t('app.contact.sorry'),
                            subtitle: this.$t('app.contact.smthwrong'),
                            buttontext: this.$t('app.contact.illtry')
                        });
                        this.notificationsDialogVisible = true;
                        EventBus.$emit('resetProForm');
                    })
            }
        }
    }
</script>
