<template>
  <v-card flat class="smfm-pro-settings-card smfm-w-100" :class="[$vuetify.breakpoint.smAndDown ? 'pa-5' : 'pa-7']">
      <div class="smfm-page-subheading mb-6">{{ $t('pro.profile.myaccount') }}</div>
      <v-avatar :size="$vuetify.breakpoint.smAndDown ? 40 : 80" class="mb-7">
          <img v-if="currentUser.picture" :src="currentUser.picture">
          <div v-else class="d-flex justify-center align-center smfm-user-initials">
              {{ userInitials }}
          </div>
      </v-avatar>
      <div v-if="userinfo">
          <v-text-field outlined name="name" v-model="userinfo.name"
            readonly
            :rules="[requiredRule]"
            :label="$t('pro.profile.name')">
          </v-text-field>
          <v-text-field outlined name="email" v-model="userinfo.email" dir="ltr"
            readonly
            :rules="[requiredRule, emailRule]"
            :label="$t('pro.profile.email')">
          </v-text-field>
      </div>
      <v-divider class="mb-7"></v-divider>
      <pro-delete-account></pro-delete-account>
  </v-card>
</template>

<script>
    import ProDeleteAccount from "@/components/pro/settings/ProDeleteAccount";
    import userMixin from "@/mixins/userMixin";
    import validation from "@/mixins/validation";

    export default {
        name: "ProAccount",
        mixins: [userMixin, validation],
        components: {ProDeleteAccount},
        data() {
            return {
                userinfo: null,
            }
        },
        created() {
            this.userinfo = {
                name: this.currentUser.name ? this.currentUser.name : '',
                email: this.currentUser.email ? this.currentUser.email : '',
            }
        },
    }
</script>