<template>
    <pro-qr-list type="dynamic" @edit="editQrStyle"></pro-qr-list>
</template>

<script>
    import ProQrList from "@/components/pro/lists/ProQrList";

    export default {
        name: "ProListDynamic",
        components: { ProQrList },
        methods: {
            editQrStyle(codeid) {
                this.$router.push(`/dynamic/${codeid}/edit`);
            },
        }        
    }
</script>
