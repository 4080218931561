<template>
    <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
            <v-btn icon @click="copy" v-bind="attrs" v-on="on">
                <v-icon>{{iconCopy}}</v-icon>
            </v-btn>
        </template>
        <span>{{$t('pro.copytoclipboard')}}</span>
    </v-tooltip>
</template>

<script>
    import { mdiContentCopy } from '@mdi/js'
    export default {
        name: "CopyToClipboard",
        props: {
            value: null,
        },
        computed: {
            iconCopy() { return mdiContentCopy }
        },
        methods: {
            copy() {
                const el = document.createElement('textarea');
                el.value = this.value;
                el.setAttribute('readonly', '');
                el.style.position = 'absolute';
                el.style.left = '-9999px';
                document.body.appendChild(el);
                const selected =  document.getSelection().rangeCount > 0  ? document.getSelection().getRangeAt(0) : false;
                el.select();
                document.execCommand('copy');
                document.body.removeChild(el);
                if (selected) {
                    document.getSelection().removeAllRanges();
                    document.getSelection().addRange(selected);
                }
            }
        }
    }
</script>