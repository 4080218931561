<template>
    <div>
        <input-files v-if="codeid" :codeid="codeid" filearea="logo" value="thumb" :max-files="1"
            :fixed-display-type="true" :label="$t('pro.forms.pagelogo')"
            :files="f.logo"
            :is-page-with-files-logo="true"
            @change="$emit('change')"
            @updated="$emit('change')">
        </input-files>
        <v-text-field outlined :label="codeid ? $t('pro.forms.pageheading') : $t('pro.forms.pagegivetitle')"
            :prepend-icon="codeid ? iconHeading : ''"
            :rules="[requiredRule]"
            v-model="v.heading" @change="$emit('change')" ref="maininput">
        </v-text-field>
        <!-- Quill editor -->
        <div v-if="codeid" class="d-flex align-center mb-8">
            <div class="v-input__prepend-outer mt-4">
                <v-icon>{{ iconText }}</v-icon>
            </div>
            <vue-editor v-model="v.description" class="quill-editor pt-2"
                        :editorToolbar="customToolbar"
                        :placeholder="$t('pro.forms.pagedescription')"
                        @text-change="$emit('change')">
            </vue-editor>
        </div>
        <input-files v-if="codeid" v-model="v.displaytype" :codeid="codeid" filearea="files"
            :label="$t('pro.forms.pagefiles')"
            :files="f.files"
            @change="$emit('change')"
            @updated="$emit('change')">
        </input-files>
    </div>
</template>

<script>
    import { mdiTextBoxOutline, mdiTagTextOutline } from '@mdi/js';
    import validation from "@/mixins/validation";
    import InputFiles from "@/components/forms/InputFiles";
    import { VueEditor } from "vue2-editor";

    export default {
        name: "DynamicFormPage",
        components: { InputFiles, VueEditor },
        mixins: [ validation ],
        props: {
            codeid: null,
        },
        data() {
            return {
                v: {
                    heading: '',
                    description: '',
                    displaytype: 'list',
                },
                f: {},
                customToolbar: [
                    [ { header: [false, 1, 2, 3, 4, 5, 6] } ],
                    [ "bold", "italic", "underline", "strike" ],
                    [ "link"/*, { 'align': [] } */],
                    [ { list: "ordered" }, { list: "bullet" }, { list: "check" } ],
                    [ { color: [] }, { background: [] } ],
                    [ "clean" ]
                ]
            }
        },

        computed: {
            value: {
                get() {
                    return this.v
                },
                set(newValue) {
                    this.v = newValue
                }
            },
            files: {
                get() {
                    return this.f
                },
                set(newValue) {
                    this.f = newValue
                }
            },
            label() {
                return this.v.heading
            },
            iconText() { return mdiTextBoxOutline },
            iconHeading() { return mdiTagTextOutline },
        },

        methods: {
            focusMain() {
                this.$refs.maininput.focus();
                this.$refs.maininput.$el.querySelector('input').select();
            },
        },
    }
</script>

<style scoped lang="scss">
.quill-editor {
    width: 100%;
    ::v-deep {
        .ql-toolbar {
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;
        }
        .ql-container {
            border-bottom-left-radius: 4px;
            border-bottom-right-radius: 4px;
            .ql-editor {
                font-family: 'Inter', sans-serif;
            }
            .ql-blank {
                &:before {
                    font-style: normal;
                    color: #C7C8CA;
                }
            }
        }
    }
}
</style>
